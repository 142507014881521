import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import pluralize from 'pluralize'
import useScreenSize from 'use-screen-size'
import { decode } from 'he'
import {
  BackArrowButton,
  ControlButton,
  IColumnElement,
  IRowElement,
  Icons,
  ListButton,
  PaginationPanel,
  SearchInput,
  SmartList,
  TooltipMenu,
  FloatingTabBar,
  Tag,
  ISelectedRows,
  ActionButton,
  IHeaderItem,
  Modal,
  ConfirmModal,
  MultiLevelMenu,
  IMenuItem,
  Badge
} from '@doseme/cohesive-ui'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { getPageCount, paginate } from '../../../../utils/pagination'
import {
  useAdminClinicianListStore,
  useAuthStore,
  useClinicianStore,
  useHospitalStore
} from '../../../../hooks/useStore'
import { sortAlpha, sortDate } from '../../../../utils/smartListUtils'
import { IAdminClinicianListItem } from '../../../../store/Admin/AdminClinicianList/types'
import { formatToDisplayDate } from '../../../../utils/dates'
import { IClinicianRoles } from '../../../../store/ClinicianStore/types'
import { TAdminCliniciansFilter } from './types'
import { buildFilterItems, getClinicianNamesFromIds, getSelectedClinicianIds, searchClinicians } from './utils'
import {
  enabledCliniciansColumns,
  itemsPerPage,
  adminCliniciansTabs,
  pendingCliniciansColumns,
  disabledCliniciansColumns,
  roleColours,
  pendingCliniciansActionData,
  disabledCliniciansActionData,
  enabledCliniciansActionData
} from './constants'
import { showErrorToast, showSuccessToast } from '../../../../shared/toast'
import { handleBackButton } from '../../../../utils/navigation'
import { IFilterResourceListItem } from '../../../../store/types'

import './index.scss'

export const ClinicianList: React.FC = observer((props) => {
  const [searchText, setSearchText] = useState('')
  const [sortColIndex, setSortColIndex] = useState(0)
  const [sortColAscending, setSortColAscending] = useState(true)
  const [sortedList, setSortedList] = useState<IRowElement[] | null>(null)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [activeCliniciansTab, setActiveCliniciansTab] = useState<TAdminCliniciansFilter>('enabledClinicians')
  const [selectedRows, setSelectedRows] = useState<ISelectedRows>({})
  const [clinicianColumns, setClinicianColumns] = useState<IHeaderItem[]>([])
  const [showDisableModal, setShowDisableModal] = useState<boolean>(false)
  const [selectedClinicianNames, setSelectedClinicianNames] = useState<string[]>([])
  const [filterItems, setFilterItems] = useState<IMenuItem[]>([])
  const [hospitalFilter, setHospitalFilter] = useState<Record<string, string>>({})
  const [roleFilter, setRoleFilter] = useState<Record<string, string>>({})

  const authStore = useAuthStore()
  const hospitalStore = useHospitalStore()
  const adminClinicianListStore = useAdminClinicianListStore()
  const clinicianStore = useClinicianStore()
  const size = useScreenSize()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (authStore.auth && authStore.isAuthenticated()) {
      hospitalStore.fetchHospital(authStore.auth.attributes.hospitalId)
    }

    adminClinicianListStore.fetchAdminClinicians()
    adminClinicianListStore.fetchClinicianListFilters()
  }, [])

  useEffect(() => {
    adminClinicianListStore.fetchAdminClinicians(Object.keys(roleFilter), Object.keys(hospitalFilter))
  }, [roleFilter, hospitalFilter])

  useEffect(() => {
    setCurrentPage(1)
    setSelectedRows({})
    if (adminClinicianListStore.loadState === 'loaded') {
      const searchedClinicians: IAdminClinicianListItem[] = searchClinicians(
        searchText,
        adminClinicianListStore.adminClinicians,
        activeCliniciansTab
      )

      let formattedClinicians = []
      setTotalPages(getPageCount(searchedClinicians.length, itemsPerPage))
      switch (activeCliniciansTab) {
        case 'enabledClinicians':
          formattedClinicians = formatEnabledClinicians(searchedClinicians, size.width, clinicianStore.clinician?.id)
          break
        case 'pendingClinicians':
          formattedClinicians = formatPendingClinicians(searchedClinicians, size.width)
          break
        case 'disabledClinicians':
          formattedClinicians = formatDisabledClinicians(searchedClinicians, size.width)
      }
      const sortedSearchedFormattedClinicians =
        activeCliniciansTab === 'pendingClinicians' && sortColIndex === 2
          ? sortDate(formattedClinicians, sortColIndex, sortColAscending)
          : sortAlpha(formattedClinicians, sortColIndex, sortColAscending)

      setSortedList(sortedSearchedFormattedClinicians)
    }
  }, [adminClinicianListStore.loadState, searchText, sortColIndex, sortColAscending, activeCliniciansTab, size.width])

  useEffect(() => {
    switch (activeCliniciansTab) {
      case 'enabledClinicians':
        setClinicianColumns(enabledCliniciansColumns(handleSort, size.width))
        break
      case 'pendingClinicians':
        setClinicianColumns(pendingCliniciansColumns(handleSort, size.width))
        break
      case 'disabledClinicians':
        setClinicianColumns(disabledCliniciansColumns(handleSort, size.width))
    }
  }, [activeCliniciansTab, size.width])

  useEffect(() => {
    if (adminClinicianListStore.filterLoadState === 'loaded') {
      const clinicianFilters = buildFilterItems(
        [...adminClinicianListStore.adminClinicianFilters.values()],
        updateFilter,
        hospitalFilter,
        roleFilter
      )
      setFilterItems(clinicianFilters)
    }
  }, [hospitalFilter, roleFilter, adminClinicianListStore.filterLoadState])

  const handleClinicianDetailsButton = (clinicianId: string) => {
    navigate(`./${clinicianId}`)
  }

  const handleHospitalAccessRedirect = (clinicianId: string) => {
    navigate(`./${clinicianId}#hospitalaccess`)
  }

  const handleInviteNewClinician = () => {
    navigate('./new')
  }

  const handleSort = (colIndex: number, ascending: boolean) => {
    setSortColIndex(colIndex)
    setSortColAscending(ascending)
  }

  const handleSearch = (searchText: string) => {
    setSearchText(searchText)
    setCurrentPage(1)
  }

  const displayRoleTags = (roles: IClinicianRoles[]): JSX.Element[] => {
    return roles.map((role, key) => {
      return (
        <div
          key={`clinicians-tags-${role.id}-${key}`}
          className={classnames('clinicians-tag', { 'admin-text-tag': role.id === '1' })}
        >
          <Tag color={roleColours[role.label]} size='medium' text={role.label} />
        </div>
      )
    })
  }

  const formatPendingClinicians = (
    clinicians: IAdminClinicianListItem[],
    screenWidth: number
  ): IRowElement[] => {
    return clinicians.map((clinician, key) => {
      const recordColumns: IColumnElement[] = []

      if (screenWidth > 1020) {
        recordColumns.push({
          name: 'Name',
          text: decode(clinician.attributes.name)
        })
      }

      recordColumns.push({
        name: 'Email',
        text: clinician.attributes.email
      }, {
        name: 'Invite expiry',
        text: clinician.attributes.inviteExpiresAt && hospitalStore.hospital?.attributes.timezone ?
          formatToDisplayDate(clinician.attributes.inviteExpiresAt, hospitalStore.hospital.attributes.timezone) :
          '-'
      },
      {
        name: '',
        element: (
          <div className='clinician-list-action-button' data-testid='ellipsis-icon'>
            <TooltipMenu
              button={
                <ListButton size='cm'>
                  <Icons.Ellipsis />
                </ListButton>
              }
              data={pendingCliniciansActionData(
                clinician,
                handleClinicianDetailsButton,
                handleResendClinicianInvite,
                handleCancelClinicianInvite
              )}
              alignRight={true}
              chevronOffset={10}
            />
          </div>
        )
      })

      return {
        id: clinician.id,
        columns: recordColumns
      }
    })
  }

  const formatDisabledClinicians = (clinicians: IAdminClinicianListItem[], screenWidth: number, currentClinicianId?: string): IRowElement[] => {
    return clinicians.map((clinician, key) => {
      const recordColumns: IColumnElement[] = [
        {
          name: 'Name',
          element: (
            <div className='clinicians-name'>
              {decode(clinician.attributes.name)} {displayRoleTags(clinician.attributes.roles)}
            </div>
          ),
          text: decode(clinician.attributes.name)
        }
      ]

      if (screenWidth > 1020) {
        recordColumns.push({
          name: 'Email',
          text: clinician.attributes.email
        })
      }

      recordColumns.push(
        {
          name: 'Disabled at',
          element: <span>-</span>,
          text: '-'
        },
        {
          name: '',
          element: (
            <div className='clinician-list-action-button' data-testid='ellipsis-icon'>
              <TooltipMenu
                button={
                  <ListButton size='cm'>
                    <Icons.Ellipsis />
                  </ListButton>
                }
                data={disabledCliniciansActionData(clinician, handleClinicianDetailsButton, handleEnableClinicians)}
                alignRight={true}
                chevronOffset={10}
              />
            </div>
          )
        }
      )

      return {
        id: clinician.id,
        columns: recordColumns,
        disableCheckBox: clinician.id === currentClinicianId
      }
    })
  }

  const formatEnabledClinicians = (clinicians: IAdminClinicianListItem[], screenWidth: number, currentClinicianId?: string): IRowElement[] => {
    return clinicians.map((clinician, key) => {
      const recordColumns: IColumnElement[] = [
        {
          name: 'Name',
          element: (
            <div className='clinicians-name'>
              {decode(clinician.attributes.name)} {displayRoleTags(clinician.attributes.roles)}
            </div>
          ),
          text: decode(clinician.attributes.name)
        }
      ]

      if (screenWidth > 1020) {
        recordColumns.push({
          name: 'Email',
          text: clinician.attributes.email
        })
      }

      recordColumns.push(
        {
          name: 'Last Activity',
          element: (
            <span>
              {hospitalStore.hospital &&
                clinician.attributes.lastActive &&
                formatToDisplayDate(clinician.attributes.lastActive, hospitalStore.hospital.attributes.timezone)}
            </span>
          ),
          text: clinician.attributes.lastActive || undefined
        },
        {
          name: '',
          element: (
            <div className='clinician-list-action-button' data-testid='ellipsis-icon'>
              <TooltipMenu
                button={
                  <ListButton size='cm'>
                    <Icons.Ellipsis />
                  </ListButton>
                }
                data={enabledCliniciansActionData(
                  clinician,
                  handleClinicianDetailsButton,
                  handleHospitalAccessRedirect,
                  handleDisableClinicians,
                  clinicianStore.clinician?.id
                )}
                alignRight={true}
                chevronOffset={10}
              />
            </div>
          )
        }
      )

      return {
        id: clinician.id,
        columns: recordColumns,
        disableCheckBox: clinician.id === currentClinicianId
      }
    })
  }

  const handleResendClinicianInvite = async (clinicianId: string) => {
    await adminClinicianListStore.resendAdminClinicianInvite(clinicianId)

    if (['updateError', 'loadError'].includes(adminClinicianListStore.loadState)) {
      showErrorToast(adminClinicianListStore.error || 'Failed to resend invite')

      return
    }

    showSuccessToast('Invite successfully resent')
  }

  const handleCancelClinicianInvite = async (clinicianId: string) => {
    await adminClinicianListStore.cancelAdminClinicianInvite(clinicianId)

    if (['updateError', 'loadError'].includes(adminClinicianListStore.loadState)) {
      showErrorToast(adminClinicianListStore.error || 'Failed to cancel invite')

      return
    }

    showSuccessToast('Invite successfully canceled')
  }

  const handleDisableClinicians = async (selectedClinicianIds: string[]) => {
    if (selectedClinicianIds.length > 0) {
      const result = await adminClinicianListStore.disableAdminClinicians(selectedClinicianIds)

      if (!result || ['updateError', 'loadError'].includes(adminClinicianListStore.loadState)) {
        showErrorToast(adminClinicianListStore.error || 'Failed to disable clinicians')

        return
      }

      showSuccessToast('Clinicians disabled')
    }
  }

  const handleEnableClinicians = async (selectedClinicianIds: string[]) => {
    if (selectedClinicianIds.length > 0) {
      const result = await adminClinicianListStore.enableAdminClinicians(selectedClinicianIds)

      if (!result || ['updateError', 'loadError'].includes(adminClinicianListStore.loadState)) {
        showErrorToast(adminClinicianListStore.error || 'Failed to enable clinicians')

        return
      }

      showSuccessToast('Clinicians enabled')
    }
  }

  const handleDisableCliniciansFromModal = () => {
    const selectedClinicianIds = getSelectedClinicianIds(selectedRows)

    handleDisableClinicians(selectedClinicianIds)
    setShowDisableModal(false)
  }

  const handleOpenDisableModal = (selectedClinicianIds: string[]) => {
    const newSelectedClinicanNames = getClinicianNamesFromIds(selectedClinicianIds, adminClinicianListStore.adminClinicians)

    setSelectedClinicianNames(newSelectedClinicanNames)
    setShowDisableModal(true)
  }

  const handleUpdatePage = (page: number) => {
    setSelectedRows({})
    setCurrentPage(page)
  }

  const getDisableEnableLabel = (disable: boolean, selectedClinicianCount: number) => {
    return (
      selectedClinicianCount === 0
        ? (
          <span>{disable ? 'Disable' : 'Enable'} multiple clinicians</span>
        ) : (
          <span>
            {disable ? 'Disable' : 'Enable'}&nbsp;
            <span className='display-selected-rows-count'>{selectedClinicianCount ? `(${selectedClinicianCount})` : ''}</span>
            {pluralize(' clinicians', selectedClinicianCount)}
          </span>
        )
    )
  }

  const displaySelectedRowsBtns = (): JSX.Element => {
    const selectedClinicianIds = getSelectedClinicianIds(selectedRows)

    if (activeCliniciansTab === 'enabledClinicians') {
      return (
        <div className='display-selected-rows-btns'>
          <ActionButton
            actionType='cancel'
            onClick={() => handleOpenDisableModal(selectedClinicianIds)}
            customLabel={getDisableEnableLabel(true, selectedClinicianIds.length)}
            disabled={selectedClinicianIds.length === 0 || adminClinicianListStore.loadState !== 'loaded'}
          />
        </div>
      )
    }

    if (activeCliniciansTab === 'disabledClinicians') {
      return (
        <div className='display-selected-rows-btns'>
          <ActionButton
            actionType='include'
            onClick={() => handleEnableClinicians(selectedClinicianIds)}
            customLabel={getDisableEnableLabel(false, selectedClinicianIds.length)}
            disabled={selectedClinicianIds.length === 0 || adminClinicianListStore.loadState !== 'loaded'}
          />
        </div>
      )
    }

    return <div className='hold-space-for-pagination'></div>
  }

  const disableModal = () => {
    return (
      <Modal
        show={showDisableModal}
        onHide={() => {
          setShowDisableModal(false)
        }}
      >
        <ConfirmModal
          entityType='clinician'
          confirmationType='disable'
          entityName={selectedClinicianNames}
          onCancel={() => setShowDisableModal(false)}
          onConfirm={() => handleDisableCliniciansFromModal()}
          title={`Disable (${selectedClinicianNames.length}) selected ${pluralize('clinician', selectedClinicianNames.length)}`}
          message='Once disabled, these clinicians will no longer be able to access DoseMeRx.'
          confirmButtonLabel={pluralize('Disable Clinician', selectedClinicianNames.length)}
        />
      </Modal>
    )
  }

  const getFilterCount = () => {
    return filterItems.reduce<number>((acc, curr) => {
      return acc + (curr.selectedCount || 0)
    }, 0)
  }

  const updateFilter = (
    type: string,
    selected: boolean,
    item: IFilterResourceListItem
  ) => {
    if (type === 'hospital') {
      const newHospitalFilter = { ...hospitalFilter }

      //Add
      if (selected) {
        newHospitalFilter[item.id] = item.name
        setHospitalFilter(newHospitalFilter)

        return
      }

      //Remove
      delete newHospitalFilter[item.id]
      setHospitalFilter(newHospitalFilter)

      return
    }

    if (type === 'individual role') {
      const newRoleFilter = { ...roleFilter }

      //Add
      if (selected) {
        newRoleFilter[item.id] = item.name
        setRoleFilter(newRoleFilter)

        return
      }

      //Remove
      delete newRoleFilter[item.id]
      setRoleFilter(newRoleFilter)

      return
    }
  }

  const buildFilterBadges = (): JSX.Element[] => {
    let badges: JSX.Element[] = []

    const roleBadges = Object.keys(roleFilter).map((id: string, key: any) => {
      const name = roleFilter[id]

      return (
        <Badge
          key={`${id}-${name}`}
          id={id}
          title={name}
          onDismiss={(id: string) => updateFilter('individual role', false, { id: id, name: name })}
        />
      )
    })
    badges = badges.concat(roleBadges)

    const hospitalBadges = Object.keys(hospitalFilter).map((id: string, key: any) => {
      const name = hospitalFilter[id]

      return (
        <Badge
          key={`${id}-${name}`}
          id={id}
          title={name}
          onDismiss={(id: string) => updateFilter('hospital', false, { id: id, name: name })}
        />
      )
    })

    return badges.concat(hospitalBadges)
  }

  return (
    <div data-testid='clinician-list' className='co-clinician-list-page'>
      {disableModal()}
      <div className='clinician-list-heading'>
        <div className='clinician-list-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() => handleBackButton('../../.', navigate, location)}
          />
        </div>
        <div className='clinician-list-title'>Clinicians</div>
        <div className='create-new-clinician-btn'>
          <ControlButton icon={faPlus} onClick={handleInviteNewClinician}>
            Invite new clinicians
          </ControlButton>
        </div>
      </div>
      <div className='clinician-list-content-panel'>
        <div className='mb-3 '>
          <FloatingTabBar
            tabNames={adminCliniciansTabs}
            activeTab={activeCliniciansTab}
            onSelectTab={(tab) => setActiveCliniciansTab(tab as TAdminCliniciansFilter)}
          />
        </div>
        <div className='clinician-list-filter-search-bar-container'>
          <div className='clinician-list-filter'>
            <MultiLevelMenu
              title='Filters'
              menuItems={filterItems}
              selectedCount={getFilterCount()}
            />
            {buildFilterBadges()}
          </div>
          <div className='clinician-list-search-bar'>
            <SearchInput
              value={searchText}
              onChange={handleSearch}
              width={360}
              borderRadius={12}
              placeholder='Search list...'
            />
          </div>
        </div>
        <div className={classnames('clinician-list-list', { disabled: activeCliniciansTab === 'disabledClinicians' })}>
          <SmartList
            cols={clinicianColumns}
            data={sortedList ? paginate(sortedList, { currentPage, itemsPerPage }) : []}
            defaultSortColumn='Name'
            defaultSortDirection='asc'
            loading={['loading', 'initial', 'updating'].includes(adminClinicianListStore.loadState)}
            textIfEmpty='No data to display'
            minRowsToShow={itemsPerPage}
            selectedRows={selectedRows}
            onRowSelect={setSelectedRows}
          />
        </div>
        <div className='clinician-pagination-panel-outer'>
          {displaySelectedRowsBtns()}
          <div className='clinician-pagination-panel'>
            <PaginationPanel currentPage={currentPage} totalPages={totalPages} onPageChange={handleUpdatePage} />
          </div>
        </div>
      </div>
    </div>
  )
})
