import { decode, encode } from 'he'
import {
  Dropdown,
  TextInput,
  IDropdownItem,
  RadioGroup,
  MultipleTextInput,
  DateTextInput,
  IRadioOption,
  IDateState
} from '@doseme/cohesive-ui'

import {
  IInputBooleanField,
  IInputFieldItem,
  IInputMultipleSelectionField,
  IInputMultipleTextField,
  IInputSelectionField,
  IInputTextField
} from '../store/types'
import { IFormField, IFormState } from '../types/validation'
import {
  isRequired,
  noDuplicatesMultipleText,
  isRequiredMultipleText,
  isStringValidInteger,
  isStringValidNumber,
  isStringWithinNumericLimits,
  isStringWithinMaxNumericLimit,
  isStringWithinMinNumericLimit,
  isHistoricalDateState,
  isDateStateAfter1900
} from '../utils/validation/rules'
import { dateStateToISODateString, stringToFloat, stringToInteger } from '../utils/validation/formatters'

export const buildFormFields = (formFormat: any): Record<string, IFormField> => {
  const keys = Object.keys(formFormat.attributes)

  return keys.reduce<Record<string, IFormField>>((acc, key) => {
    const field = formFormat.attributes[key]
    let formatter = undefined
    let constraints = null
    const rules = []
    let initialInput = null

    switch (field.type) {
      case 'text':
      case 'multipleText':
        if (field.type === 'text') {
          initialInput = field.attributes.currentValue
        } else if (field.type === 'multipleText') {
          initialInput = field.attributes.currentValues
        }

        if (field.attributes.isRequired) {
          if (field.type === 'text') {
            rules.push(isRequired)
          } else if (field.type === 'multipleText') {
            rules.push(isRequiredMultipleText)
          }
        }

        if (field.type === 'multipleText') {
          rules.push(noDuplicatesMultipleText)
        }

        if (field.attributes.format === 'integer') {
          rules.push(isStringValidInteger, isStringValidNumber)
          formatter = stringToInteger
        } else if (field.attributes.format === 'number') {
          rules.push(isStringValidNumber)
          formatter = stringToFloat
        } else if (field.attributes.format === 'date') {
          rules.push(isHistoricalDateState, isDateStateAfter1900)
          formatter = dateStateToISODateString
          if (initialInput) {
            const dateParts = initialInput.split('-')
            initialInput = {
              dd: dateParts[2],
              mm: dateParts[1],
              yyyy: dateParts[0]
            } as IDateState
          }
        }

        if (field.attributes.format === 'integer' || field.attributes.format === 'number') {
          if (
            (field.attributes.minValue || field.attributes.minValue === 0) &&
            (field.attributes.maxValue || field.attributes.minValue === 0)
          ) {
            rules.push(isStringWithinNumericLimits)
            constraints = {
              min: {
                value: field.attributes.minValue,
                unit: field.attributes.units?.name ? field.attributes.units : { name: field.attributes.units }
              },
              max: {
                value: field.attributes.maxValue,
                unit: field.attributes.units?.name ? field.attributes.units : { name: field.attributes.units }
              }
            }
          } else if (field.attributes.minValue || field.attributes.minValue === 0) {
            rules.push(isStringWithinMinNumericLimit)
            constraints = {
              min: {
                value: field.attributes.minValue,
                unit: field.attributes.units?.name ? field.attributes.units : { name: field.attributes.units }
              }
            }
          } else if (field.attributes.maxValue || field.attributes.maxValue === 0) {
            rules.push(isStringWithinMaxNumericLimit)
            constraints = {
              max: {
                value: field.attributes.maxValue,
                unit: field.attributes.units?.name ? field.attributes.units : { name: field.attributes.units }
              }
            }
          }
        }

        return {
          ...acc,
          [key]: {
            rules: rules,
            initialInput: initialInput,
            formatter: formatter,
            initialConstraints: constraints
          }
        }
      case 'selection':
        initialInput = field.attributes.currentValue?.value
          || field.attributes.currentValue?.label
          || field.attributes.currentValue
          || field.attributes.defaultValue?.value
          || field.attributes.defaultValue?.label
          || field.attributes.defaultValue

        return {
          ...acc,
          [key]: {
            initialInput: initialInput,
            rules: field.attributes.isRequired ? [isRequired] : []
          }
        }
      case 'boolean':
        return {
          ...acc,
          [key]: {
            initialInput: field.attributes.currentValue || field.attributes.defaultValue,
            rules: field.attributes.isRequired ? [isRequired] : []
          }
        }
      case 'multipleSelection':
        return {
          ...acc,
          [key]: {
            initialInput: field.attributes.currentValues || field.attributes.defaultValues,
            rules: field.attributes.isRequired ? [isRequired] : []
          }
        }
    }

    return acc
  }, {})
}

export const buildInputs = (
  formFormat: any,
  form: IFormState,
  orderInputs: string[],
  formFields: Record<string, IFormField>
): JSX.Element[] => {
  return orderInputs.reduce<JSX.Element[]>((acc, key) => {
    const field = formFormat.attributes[key]
    switch (field.type) {
      case 'text':
        if (field?.attributes?.format === 'date') {
          return acc.concat(buildDateInput(field, form, key))
        }

        return acc.concat(buildTextInput(field, form, formFields[key], key))
      case 'selection':
        return acc.concat(buildSelectionInput(field, form, key))
      case 'boolean':
        return acc.concat(buildBooleanInput(field, form, key))
      case 'multipleSelection':
        return acc.concat(buildMultipleSelectionInput(field, form, key))
      case 'multipleText':
        return acc.concat(buildMultipleTextInput(field, form, formFields[key], key))
    }

    return acc
  }, [])
}

export const buildBooleanInput = (textField: IInputBooleanField, form: IFormState, key: string): JSX.Element => {
  const options = [
    {
      id: 'Yes',
      value: 'Yes',
      display: 'Yes'
    },
    {
      id: 'No',
      value: 'No',
      display: 'No'
    }
  ]

  return (
    <RadioGroup
      key={key}
      label={textField.attributes.label}
      selected={form.inputs[key] ? 'Yes' : 'No'}
      options={options}
      onSelect={(value) =>
        form.validateFields([
          {
            field: key,
            input: value.value === 'Yes'
          }
        ])
      }
    />
  )
}

export const buildTextInput = (
  textField: IInputTextField,
  form: IFormState,
  formField: IFormField,
  key: string,
  hideLabel?: boolean,
  type?: 'text' | 'password'
): JSX.Element => {
  const units =
    typeof textField.attributes.units === 'string' ? textField.attributes.units : textField.attributes.units?.name

  return (
    <TextInput
      key={key}
      type={type || 'text'}
      label={hideLabel ? '' : textField.attributes.label}
      fieldState={form.getValidState(key)}
      validationText={form.getValidationMsg(key)}
      value={decode(form.inputs[key] || '', { isAttributeValue: true })}
      name={key}
      required={textField.attributes.isRequired}
      units={units}
      onChange={(value) =>
        form.validateFields([
          {
            field: key,
            input: value,
            constraints: formField.initialConstraints
          }
        ])
      }
      onBlur={() => form.updateFieldsDisplay([key])}
      placeholder={textField.attributes.defaultValue ? textField.attributes.defaultValue : undefined}
    />
  )
}

export const buildDateInput = (textField: IInputTextField, form: IFormState, key: string) => {
  return (
    <DateTextInput
      key={key}
      label={textField.attributes.label}
      showOptional={!textField.attributes.isRequired}
      fieldState={form.getValidState(key)}
      validationText={form.getValidationMsg(key)}
      initialValue={form.inputs[key]}
      onDateChange={(e, updatedValue) => {
        form.validateFields([
          {
            field: 'dob',
            input: updatedValue
          }
        ])
      }}
      onBlur={() => {
        form.updateFieldsDisplay([key])
      }}
      format={window.env.DOB_DATE_FORMAT || 'MM/DD/YYYY'}
    />
  )
}

export const buildMultipleTextInput = (
  multipleTextField: IInputMultipleTextField,
  form: IFormState,
  formField: IFormField,
  key: string,
  addLabel?: string,
  placeholder?: string
): JSX.Element => {
  return (
    <MultipleTextInput
      key={key}
      required={multipleTextField.attributes.isRequired}
      label={multipleTextField.attributes.label}
      fieldState={form.getValidState(key)}
      validationText={form.getValidationMsg(key)}
      values={form.inputs[key]}
      name={key}
      placeholder={placeholder}
      addLabel={addLabel}
      onChange={(values) =>
        form.validateFields([
          {
            field: key,
            input: values,
            constraints: formField.initialConstraints
          }
        ])
      }
      onBlur={() => form.updateFieldsDisplay([key])}
    />
  )
}

// FIXME IFE-1068 - update here/cohesive-ui to ensure:
//  - the _displayed_ values shown inside the input text fields are HTML decoded to UTF-8 characters
//  - and that on form _submission_ those UTF-8 characters are properly HTML _encoded_ to send via JSON
//    to the backend for writing into the DB

export const buildMultipleSelectionInput = (
  multipleSelectionField: IInputMultipleSelectionField,
  form: IFormState,
  key: string
): JSX.Element => {
  return (
    <Dropdown
      key={key}
      showSearchThreshold={5}
      multiSelect
      showOptional={!multipleSelectionField.attributes.isRequired}
      label={multipleSelectionField.attributes.label}
      fieldState={form.getValidState(key)}
      validationText={form.getValidationMsg(key)}
      data={formatMultipleSelectionOptions(multipleSelectionField.attributes.allowedValues)}
      values={formatMultipleSelectionValues(form.inputs[key])}
      onSelectMultiple={(items) =>
        form.validateFields(
          [
            {
              field: key,
              input: formatMultipleSelectionReturnedValues(items)
            }
          ],
          'updateFieldsDisplay'
        )
      }
      placeholder='Select an item'
    />
  )
}

export const formatMultipleSelectionOptions = (allowedValues: any[] | null): IDropdownItem[] => {
  if (allowedValues && allowedValues.length !== 0) {
    return allowedValues.map((allowedValue: any) => {
      if (allowedValue.value || allowedValue.label) {
        return {
          id: allowedValue.id,
          label: decode(allowedValue.label || ''),
          value: decode(allowedValue.value || '') || decode(allowedValue.label || ''),
          shortLabel: decode(allowedValue.shortLabel || ''),
          default: false
        }
      }

      return {
        value: allowedValue
      }
    })
  }

  return []
}

export const formatMultipleSelectionReturnedValues = (currentValues: any[]) => {
  if (currentValues && currentValues.length !== 0) {
    return currentValues.map((currentValue: any) => {
      if (currentValue.value || currentValue.label) {
        return {
          id: currentValue.id,
          label: encode(currentValue.label || ''),
          value: encode(currentValue.value || currentValue.label || ''),
          shortLabel: encode(currentValue.shortLabel || '')
        }
      }

      return currentValue
    })
  }
}

export const formatMultipleSelectionValues = (currentValues: any[]) => {
  if (currentValues && currentValues.length !== 0) {
    return currentValues.map((currentValue: any) => {
      if (currentValue.value || currentValue.label) {
        return (currentValue.value && typeof currentValue.value === 'string'
          ? decode(currentValue.value)
          : currentValue.value
        ) || decode(currentValue.label)
      }

      return (typeof currentValue === 'string')
        ? decode(currentValue)
        : currentValue
    })
  }

  return []
}

export const buildSelectionInput = (
  selectionField: IInputSelectionField,
  form: IFormState,
  key: string
): JSX.Element => {
  return (
    <Dropdown
      key={key}
      showSearchThreshold={5}
      showOptional={!selectionField.attributes.isRequired}
      label={selectionField.attributes.label}
      fieldState={form.getValidState(key)}
      validationText={form.getValidationMsg(key)}
      data={formatSelectionOptions(selectionField.attributes.allowedValues, selectionField.attributes.defaultValue)}
      value={form.inputs[key]}
      onSelect={(item) => {
        form.validateFields(
          [
            {
              field: key,
              input: item.value
            }
          ],
          'updateFieldsDisplay'
        )
      }}
      onClear={
        !selectionField.attributes.isRequired
          ? () => {
            form.validateFields(
              [
                {
                  field: key,
                  input: null
                }
              ],
              'updateFieldsDisplay'
            )
          }
          : undefined
      }
      placeholder='Select an item'
    />
  )
}

export const formatSelectionOptions = (allowedValues: any, defaultValue: any): IDropdownItem[] => {
  if (allowedValues && allowedValues.length !== 0) {
    return allowedValues.map((allowedValue: any) => {
      let isDefault = false

      if (allowedValue.value || allowedValue.label) {
        if (defaultValue && allowedValue.value === defaultValue?.value) {
          isDefault = true
        }

        return {
          label: decode(allowedValue.label, { isAttributeValue: true }),
          value: (allowedValue.value && typeof allowedValue.value === 'string'
            ? decode(allowedValue.value, { isAttributeValue: true })
            : allowedValue.value
          ) || decode(allowedValue.label, { isAttributeValue: true }),
          default: isDefault,
          defaultText: isDefault ? allowedValue.defaultText : undefined
        }
      }

      if (defaultValue && allowedValue === defaultValue) {
        isDefault = true
      }

      return {
        label: decode(allowedValue, { isAttributeValue: true }),
        value: (typeof allowedValue === 'string')
          ? decode(allowedValue, { isAttributeValue: true })
          : allowedValue,
        default: isDefault,
        defaultText: isDefault ? allowedValue.defaultText : undefined
      }
    })
  }

  return []
}

export const buildRadioGroup = (selectionField: IInputSelectionField, form: IFormState, key: string) => {
  return (
    <RadioGroup
      key={key}
      isRequired={selectionField.attributes.isRequired}
      label={selectionField.attributes.label}
      selected={form.inputs[key]}
      options={formatRadioGroupOptions(selectionField.attributes.allowedValues)}
      onSelect={(opt) =>
        form.validateFields(
          [
            {
              field: key,
              input: opt.id
            }
          ],
          'updateFieldsDisplay'
        )
      }
    />
  )
}
const formatRadioGroupOptions = (allowedValues: string[] | IInputFieldItem[] | null): IRadioOption[] => {
  if (allowedValues && allowedValues.length !== 0) {
    return allowedValues.map((allowedValue: string | IInputFieldItem): IRadioOption => {
      if (typeof allowedValue !== 'string') {
        return {
          id: allowedValue.id || allowedValue.value,
          value: allowedValue.value,
          display: allowedValue.label
        }
      }

      return {
        display: allowedValue,
        value: allowedValue,
        id: allowedValue
      }
    })
  }

  return []
}
