import { TLoadState } from '../types'
import { IModelResults, IModelResultsTypes, TModelType } from './types'

export const getModelResults = (
  modelResultsTypes: IModelResultsTypes,
  selectedSimulationPanelTab: TModelType,
  loadState: TLoadState
): IModelResults | null => {
  if (['loading', 'updating'].includes(loadState)) {
    return null
  }

  if (selectedSimulationPanelTab === 'customTarget') {
    return modelResultsTypes.customTarget
  }

  if (selectedSimulationPanelTab === 'customDose') {
    return modelResultsTypes.customDose
  }

  if (selectedSimulationPanelTab === 'guideline') {
    return modelResultsTypes.guideline
  }

  return modelResultsTypes.indPop
}
