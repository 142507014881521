import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  DOSEME_BLUE,
  InfoBubble,
  VerificationCodeInputs,
  WHITE
} from '@doseme/cohesive-ui'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { jwtDecode } from 'jwt-decode'
import { observer } from 'mobx-react-lite'

import { showErrorToast, showSuccessToast } from '../../../../shared/toast'
import { TOSFooter } from '../TOSFooter'
import { PasswordPanel } from '../PasswordPanel'
import { IFormState } from '../../../../types/validation'
import { useAuthStore } from '../../../../hooks/useStore'
import { verificationCodeInitialValue } from './constants'

import '../../index.scss'

export const ResetPasswordPage: React.FC = observer(() => {
  const [displayPasswordPanel, setDisplayPasswordPanel] = useState<boolean>(false)
  const [verificationCode, setVerificationCode] = useState<string[]>(verificationCodeInitialValue)
  const [isLinkInvalid, setIsLinkInvalid] = useState<boolean>(false)
  const [resetCodeUpdateError, setResetCodeUpdateError] = useState<string | undefined>()
  const [passwordUpdateError, setPasswordUpdateError] = useState<string | undefined>()

  const authStore = useAuthStore()

  const { search } = useLocation()

  const navigate = useNavigate()

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      const decoded = jwtDecode(token)

      setIsLinkInvalid(!decoded.exp || decoded.exp * 1000 < Date.now())
    }
  }, [])

  const resendResetEmail = async () => {
    const token = searchParams.get('token')
    if (!token) {
      return
    }

    const decoded: any = jwtDecode(token)
    await authStore.authResetPasswordEmail(decoded.email)

    if (['loadError', 'updateError'].includes(authStore.loadState)) {
      showErrorToast(authStore.error || 'Failed to send password reset email')

      return
    }

    showSuccessToast('Password reset email sent')
  }

  const savePassword = async (form: IFormState) => {
    setResetCodeUpdateError(undefined)
    setPasswordUpdateError(undefined)

    const email = searchParams.get('email')
    const token = searchParams.get('token')

    if (email && token) {
      await authStore.authResetPassword(email, form.values['newPassword'], verificationCode.join(''), token)
    }

    if (['loadError', 'updateError'].includes(authStore.loadState)) {
      // Check if the request failed because the code was incorrect
      if (authStore.error === 'Invalid Reset code supplied') {
        setVerificationCode(verificationCodeInitialValue)
        setDisplayPasswordPanel(false)
        setResetCodeUpdateError(authStore.error)

        return
      }

      // Check if the request failed because of a password issue
      if (authStore.error !== 'Invalid password reset request'
        && (authStore.error.includes('password') || authStore.error.includes('Password'))) {
        setDisplayPasswordPanel(true)
        setPasswordUpdateError(authStore.error)

        return
      }

      setVerificationCode(verificationCodeInitialValue)
      setDisplayPasswordPanel(false)
      showErrorToast(authStore.error || 'Failed to update password')

      return
    }

    showSuccessToast('Saved password')
    navigate('/login')
  }

  const emailCodeContent = () => {
    return (
      <div className='login-page-panel smaller-width reset-password-page'>
        <div className='login-page-title'>
          <div className='reset-password-page-number'>1</div>
          Enter unique code
        </div>
        <div className='login-page-password-subtitle'>{searchParams.get('email')}</div>
        <div className='reset-code-input-title'>Enter the 7-digit code included in the password reset email</div>
        <div className='reset-code-inputs-outer'>
          <VerificationCodeInputs
            code={verificationCode}
            setCode={setVerificationCode}
            resetPasswordCode
            onSubmit={() => setDisplayPasswordPanel(true)}
          />
        </div>
        {resetCodeUpdateError && (
          <div className='reset-password-error'>
            <InfoBubble type='error' bubbleTitle={resetCodeUpdateError} borderRadius={6} />
          </div>
        )}
        <Button onClick={() => setDisplayPasswordPanel(true)} variant='primary-alt' disabled={verificationCode.includes('')}>
          Continue&nbsp;
          <FontAwesomeIcon color={!verificationCode.includes('') ? DOSEME_BLUE : WHITE} icon={faArrowRight} />
        </Button>
      </div>
    )
  }

  if (isLinkInvalid) {
    return (
      <>
        <div className='login-page-panel smaller-width invalid-link-page-panel'>
          <div className='invalid-link-title'>Oh no! Invalid link</div>
          <div className='login-page-subtitle'>It looks like this reset link is no longer valid.</div>
          <div className='invalid-link-body'>That’s ok though, we can send you a new one!</div>
          <div className='invalid-link-info-bubble'>
            <InfoBubble bubbleTitle='Quick reminder that you can only use the link once, and it will only be valid for a limited amount of time.' />
          </div>
          <Button
            onClick={() => resendResetEmail()}
            variant='primary-alt'>
            Send me a new link&nbsp;
            <FontAwesomeIcon color={DOSEME_BLUE} icon={faArrowRight} />
          </Button>
        </div>
        <TOSFooter />
      </>
    )
  }

  return (
    <div>
      {!displayPasswordPanel ?
        emailCodeContent() :
        <div className='login-page-panel smaller-width reset-password-page'>
          <div className='login-page-title'>
            <div className='reset-password-page-number'>2</div>
            Create new password
          </div>

          <div className='login-page-password-subtitle'>{searchParams.get('email')}</div>

          <PasswordPanel
            savePassword={savePassword}
            passwordUpdateError={passwordUpdateError}
          />
        </div>
      }
      <TOSFooter />
    </div>
  )
})
