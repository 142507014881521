import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ActionButton, BackArrowButton, SidePanel } from '@doseme/cohesive-ui'

import { useAdminVendorListStore, useAdminVendorSiteDetailsStore } from '../../../../../../hooks/useStore'
import { IAdminVendorSiteDetails } from '../../../../../../store/Admin/AdminVendorSiteDetails/types'
import { handleBackButton } from '../../../../../../utils/navigation'
import { VendorSiteCoreDetailsModal } from './SettingsModals/VendorSiteCoreDetailsModal'
import { VendorSiteSettingsModal } from './SettingsModals/VendorSiteSettingsModal'

import './index.scss'

export const VendorSiteDetails: React.FC = observer(() => {
  const [vendorSiteDetails, setVendorSiteDetails] = useState<IAdminVendorSiteDetails | null>(null)
  const [showSiteDetailsModal, setShowSiteDetailsModal] = useState<boolean>(false)
  const [showSiteSettingsModal, setShowSiteSettingsModal] = useState<boolean>(false)
  const [vendorName, setVendorName] = useState<string | undefined>(undefined)

  const vendorSiteDetailsStore = useAdminVendorSiteDetailsStore()
  const adminVendorListStore = useAdminVendorListStore()

  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const vendorId = params['vendorId']!
  const vendorSiteId = params['vendorSiteId']!

  useEffect(() => {
    if (vendorSiteDetailsStore.loadState === 'loaded' && vendorSiteDetailsStore.adminVendorSiteDetails) {
      setVendorSiteDetails(vendorSiteDetailsStore.adminVendorSiteDetails)
    }
  }, [vendorSiteDetailsStore.loadState, vendorSiteDetailsStore.settingsLoadStates])

  useEffect(() => {
    if (adminVendorListStore.loadState === 'initial') {
      adminVendorListStore.fetchVendors()
    }

    if (!showSiteDetailsModal && !showSiteSettingsModal) {
      vendorSiteDetailsStore.fetchAdminVendorSiteDetails(vendorId, vendorSiteId)
    }
  }, [showSiteDetailsModal, showSiteSettingsModal])

  useEffect(() => {
    if (adminVendorListStore.loadState === 'loaded') {
      const vendorName = adminVendorListStore.adminVendors.get(vendorId)?.attributes.name
      setVendorName(vendorName)
    }
  }, [adminVendorListStore.loadState])

  const siteDetailsButton: JSX.Element = (
    <div>
      <ActionButton
        actionType='edit'
        onClick={() => setShowSiteDetailsModal(true)}
      />
    </div>
  )

  const siteSettingsButton: JSX.Element = (
    <div>
      <ActionButton
        actionType='edit'
        onClick={() => setShowSiteSettingsModal(true)}
      />
    </div>
  )

  const displayFormattedDetails = (data: Map<string, string | undefined>): JSX.Element => {
    let returnElements: JSX.Element[] = []
    let i = 0
    for (const [title, value] of data) {
      i++
      returnElements.push(
        <div key={title} className='d-flex mb-2 ml-3'>
          <b>{title}</b>
          <div className='ml-2'>{value}</div>
        </div>
      )
      if (i !== data.size) {
        returnElements.push(
          <hr key={`${title}-hr`}></hr>
        )
      }
    }

    return <div className='display-formatted-details'>{returnElements}</div>
  }

  return (
    <div data-testid='vendor-site-show' className='co-vendor-site-show-page'>
      <VendorSiteCoreDetailsModal
        show={showSiteDetailsModal}
        setShow={setShowSiteDetailsModal}
        vendorId={vendorId}
        vendorSiteId={vendorSiteId}
      />
      <VendorSiteSettingsModal
        show={showSiteSettingsModal}
        setShow={setShowSiteSettingsModal}
        vendorSiteId={vendorSiteId}
        vendorSiteName={vendorSiteDetails?.attributes.hospital.name}
        vendorId={vendorId}
      />
      <div className='d-flex'>
        <div className='vendor-site-show-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() =>
              handleBackButton('../.', navigate, location)
            }
          />
        </div>
        <div className='vendor-site-show-title'>
          Vendor site: <b>{vendorSiteDetails?.attributes.name}</b>
        </div>
      </div>
      <div className='vendor-site-subtitle'>{vendorName}</div>
      <SidePanel
        title='Vendor site details'
        actionButton={siteDetailsButton}
        loading={['loading', 'updating'].includes(vendorSiteDetailsStore.loadState)}
      >
        {displayFormattedDetails(
          new Map([
            ['Hospital:', vendorSiteDetails?.attributes.hospital.name],
            ['Name:', vendorSiteDetails?.attributes.name],
            ['Site key:', vendorSiteDetails?.attributes.key]
          ])
        )}
      </SidePanel>
      <div className='mt-3'>
        <SidePanel
          title='Site settings'
          actionButton={siteSettingsButton}
          loading={['loading', 'updating'].includes(vendorSiteDetailsStore.loadState)}
        >
          {displayFormattedDetails(
            new Map([
              ['Report system:', vendorSiteDetails?.attributes.reportSystem],
              ['Report code:', vendorSiteDetails?.attributes.reportCode],
              ['Patient height observation LOINC codes:', vendorSiteDetails?.attributes.patientHeightObservationCodes],
              ['Patient weight observation LOINC codes:', vendorSiteDetails?.attributes.patientWeightObservationCodes]
            ])
          )}
        </SidePanel>
      </div>
    </div>
  )
})
