import { useEffect } from 'react'
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { ToastContainer } from 'react-toastify'
import useScreenSize from 'use-screen-size'
import { Skeleton } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'

import { useAuthStore, useClinicianStore, useHospitalStore } from './hooks/useStore'
import { IntegratedSessionMiddleware } from './components/SessionMiddleware'
import { buildPatientRoutes } from './routing/routeBuilder'
import { reactPlugin } from './components/AzureAppInsights'
import { AppInsightsErrorBoundary, DebugError } from './components/AppInsightsErrorBoundary'
import { Launch } from './components/Launch'
import { TopMenuBar } from './components/TopMenuBar'
import { IntegratedFooter } from './components/IntegratedFooter'
import { StoreProvider } from './store/StoreProvider'
import { ScrollToTop } from './shared/ScrollToTop'

import './index.scss'

interface IWrapperProps {
  width: number
}

const LaunchNavWrapper: React.FC<IWrapperProps> = (props) => {
  return (
    <div>
      <ScrollToTop />
      <div className='app-wrapper'>
        <AppInsightsContext.Provider value={reactPlugin}>
          <TopMenuBar />
          {props.width < 820 ? (
            <div className='unsupported-device'>
              <Skeleton.UnsupportedWidth />
            </div>
          ) : (
            <AppInsightsErrorBoundary>
              <Outlet />
            </AppInsightsErrorBoundary>
          )}
          <IntegratedFooter width={props.width} />
        </AppInsightsContext.Provider>
      </div>
    </div>
  )
}

const NavWrapper: React.FC<IWrapperProps> = (props) => {
  return (
    <div>
      <ScrollToTop />
      <div className='app-wrapper'>
        <AppInsightsContext.Provider value={reactPlugin}>
          <TopMenuBar />
          {props.width < 820 ? (
            <div className='unsupported-device'>
              <Skeleton.UnsupportedWidth />
            </div>
          ) : (
            <AppInsightsErrorBoundary>
              <IntegratedSessionMiddleware>
                <Outlet />
              </IntegratedSessionMiddleware>
            </AppInsightsErrorBoundary>
          )}
          <IntegratedFooter width={props.width} />
        </AppInsightsContext.Provider>
      </div>
    </div>
  )
}

const IntegratedApp: React.FC = observer(() => {
  const size = useScreenSize()

  const authStore = useAuthStore()
  const clinicianStore = useClinicianStore()
  const hospitalStore = useHospitalStore()

  useEffect(() => {
    if (clinicianStore.loadState === 'initial' && authStore.auth && authStore.isAuthenticated()) {
      clinicianStore.fetchClinician(authStore.auth.attributes.clinicianId)
    }

    if (hospitalStore.loadState === 'initial' && authStore.auth && authStore.isAuthenticated()) {
      hospitalStore.fetchHospital(authStore.auth.attributes.hospitalId)
    }
  }, [authStore.auth, authStore.loadState])

  const integratedAppRoutes = [
    buildPatientRoutes()
  ]

  const router = createBrowserRouter([
    {
      path: '/launch',
      element: <LaunchNavWrapper width={size.width} />,
      children: [
        {
          path: '/launch',
          element: <Launch />
        }
      ]
    },
    {
      path: '/medication',
      element: <LaunchNavWrapper width={size.width} />,
      children: [
        {
          path: '/medication',
          element: <Launch />
        }
      ]
    },
    {
      path: '/patients',
      element: <NavWrapper width={size.width} />,
      children: integratedAppRoutes
    },
    {
      path: '/',
      loader: ({ request }) => {
        const url = new URL(request.url)
        const code = url.searchParams.get('code')
        const state = url.searchParams.get('state')

        if (!code || !state) {
          throw new DebugError(`Launch params missing: ${code} & ${state}`)
        }

        return null
      },
      element: <LaunchNavWrapper width={size.width} />,
      children: [
        {
          path: '/',
          element: <Launch />
        }
      ]
    },
    {
      path: '*',
      loader: () => {
        throw new DebugError(`Invalid route: ${location.pathname}`)
      },
      element: <NavWrapper width={size.width} />
    }
  ])

  return (
    <>
      <ToastContainer newestOnTop={true} closeOnClick={true} />
      <StoreProvider>
        <RouterProvider router={router} />
      </StoreProvider>
    </>
  )
})

export {
  IntegratedApp
}
