import { BLACK, GREY, Icons } from '@doseme/cohesive-ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useScreenSize from 'use-screen-size'

import { useClinicianStore } from '../../../../hooks/useStore'

import './index.scss'

export const AdminHub: React.FC = () => {
  const [hospitalDivHover, setHospitalDivHover] = useState<boolean>(false)
  const [cliniciansDivHover, setCliniciansDivHover] = useState<boolean>(false)
  const [changelLogDivHover, setChangelLogDivHover] = useState<boolean>(false)
  const [drugSettingsDivHover, setDrugSettingsDivHover] = useState<boolean>(false)
  const [vendorSettingsDivHover, setVendorSettingsDivHover] = useState<boolean>(false)

  const clinicianStore = useClinicianStore()
  const size = useScreenSize()
  const navigate = useNavigate()

  const handleHospitalsLink = () => {
    const onlyAdminHospital = clinicianStore.onlyAdminHospital()

    if (onlyAdminHospital) {
      navigate('./hospitals/' + onlyAdminHospital)

      return
    }

    navigate('./hospitals')
  }

  const handleCliniciansLink = () => {
    navigate('./clinicians')
  }

  const handleChangeLogLink = () => {
    navigate('./changelog')
  }

  const handleDrugSettingsLink = () => {
    const onlyAdminHospital = clinicianStore.onlyAdminHospital()

    if (onlyAdminHospital) {
      navigate(`./hospitals/${onlyAdminHospital}/drugs`)

      return
    }

    navigate('./drugsettings')
  }

  const handleVendorSettingsLink = () => {
    navigate('./vendors')
  }

  if (size.width <= 1150) {
    return (
      <div className='co-admin-hub'>
        <div className='narrow'>
          <div className='admin-hub-title'>Admin hub</div>
          <div className='admin-page-panel-block'>
            <div
              className='admin-panel'
              onClick={handleHospitalsLink}
              onMouseLeave={() => setHospitalDivHover(false)}
              onMouseOver={() => setHospitalDivHover(true)}
            >
              <div className='admin-panel-title hospital'>Hospital settings</div>
              <div className='admin-panel-text'>Edit hospital details</div>
              <div className='admin-panel-text'>General dosing and report settings</div>
              <div className='admin-panel-text'>View available drugs</div>

              <div className='admin-arrow-link'>
                <Icons.BackArrowRounded fill={hospitalDivHover ? BLACK : GREY} />
              </div>
            </div>

            <div
              className='admin-panel'
              onClick={handleCliniciansLink}
              onMouseLeave={() => setCliniciansDivHover(false)}
              onMouseOver={() => setCliniciansDivHover(true)}
            >
              <div className='admin-panel-title clinicians'>Clinicians</div>
              <div className='admin-panel-text'>View clinicians at your hospital</div>
              <div className='admin-panel-text'>Manage roles, departments</div>
              <div className='admin-panel-text'>Individual clinician settings</div>

              <div className='admin-arrow-link'>
                <Icons.BackArrowRounded fill={cliniciansDivHover ? BLACK : GREY} />
              </div>
            </div>
          </div>

          <div className='admin-page-panel-block narrow'>
            <div
              className='admin-panel'
              onClick={handleDrugSettingsLink}
              onMouseLeave={() => setDrugSettingsDivHover(false)}
              onMouseOver={() => setDrugSettingsDivHover(true)}
            >
              <div className='admin-panel-title drugsettings'>Drug settings</div>
              <div className='admin-panel-text'>Drug model settings</div>
              <div className='admin-panel-text'>Dose simulation settings and defaults</div>

              <div className='admin-arrow-link'>
                <Icons.BackArrowRounded fill={drugSettingsDivHover ? BLACK : GREY} />
              </div>
            </div>

            <div
              className='admin-panel'
              onClick={handleChangeLogLink}
              onMouseLeave={() => setChangelLogDivHover(false)}
              onMouseOver={() => setChangelLogDivHover(true)}
            >
              <div className='admin-panel-title changelog'>Change log</div>
              <div className='admin-panel-text'>View a log of any changes made to settings in the admin panel</div>

              <div className='admin-arrow-link'>
                <Icons.BackArrowRounded fill={changelLogDivHover ? BLACK : GREY} />
              </div>
            </div>
          </div>

          <div className='admin-page-panel-block'>
            {clinicianStore.clinician?.attributes.isSuperAdmin && (
              <div
                className='admin-panel'
                onClick={handleVendorSettingsLink}
                onMouseLeave={() => setVendorSettingsDivHover(false)}
                onMouseOver={() => setVendorSettingsDivHover(true)}
              >
                <div className='admin-panel-title vendorsettings'>Vendor settings</div>
                <div className='admin-panel-text'>
                  <i>Internal use only.</i>
                </div>

                <div className='admin-arrow-link'>
                  <Icons.BackArrowRounded fill={vendorSettingsDivHover ? BLACK : GREY} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='co-admin-hub'>
      <div className='admin-hub-title'>Admin hub</div>
      <div className='admin-page-panel-block'>
        <div
          className='admin-panel'
          onClick={handleHospitalsLink}
          onMouseLeave={() => setHospitalDivHover(false)}
          onMouseOver={() => setHospitalDivHover(true)}
        >
          <div className='admin-panel-title hospital'>Hospital settings</div>
          <div className='admin-panel-text'>Edit hospital details</div>
          <div className='admin-panel-text'>General dosing and report settings</div>
          <div className='admin-panel-text'>View available drugs</div>

          <div className='admin-arrow-link'>
            <Icons.BackArrowRounded fill={hospitalDivHover ? BLACK : GREY} />
          </div>
        </div>
        <div
          className='admin-panel'
          onClick={handleCliniciansLink}
          onMouseLeave={() => setCliniciansDivHover(false)}
          onMouseOver={() => setCliniciansDivHover(true)}
        >
          <div className='admin-panel-title clinicians'>Clinicians</div>
          <div className='admin-panel-text'>View clinicians at your hospital</div>
          <div className='admin-panel-text'>Manage roles, departments</div>
          <div className='admin-panel-text'>Individual clinician settings</div>

          <div className='admin-arrow-link'>
            <Icons.BackArrowRounded fill={cliniciansDivHover ? BLACK : GREY} />
          </div>
        </div>
        <div
          className='admin-panel'
          onClick={handleDrugSettingsLink}
          onMouseLeave={() => setDrugSettingsDivHover(false)}
          onMouseOver={() => setDrugSettingsDivHover(true)}
        >
          <div className='admin-panel-title drugsettings'>Drug settings</div>
          <div className='admin-panel-text'>Drug model settings</div>
          <div className='admin-panel-text'>Dose simulation settings and defaults</div>

          <div className='admin-arrow-link'>
            <Icons.BackArrowRounded fill={drugSettingsDivHover ? BLACK : GREY} />
          </div>
        </div>
      </div>

      <div className='admin-page-panel-block'>
        <div
          className='admin-panel'
          onClick={handleChangeLogLink}
          onMouseLeave={() => setChangelLogDivHover(false)}
          onMouseOver={() => setChangelLogDivHover(true)}
        >
          <div className='admin-panel-title changelog'>Change log</div>
          <div className='admin-panel-text'>View a log of any changes made to settings in the admin panel</div>

          <div className='admin-arrow-link'>
            <Icons.BackArrowRounded fill={changelLogDivHover ? BLACK : GREY} />
          </div>
        </div>
        {clinicianStore.clinician?.attributes.isSuperAdmin && (
          <div
            className='admin-panel'
            onClick={handleVendorSettingsLink}
            onMouseLeave={() => setVendorSettingsDivHover(false)}
            onMouseOver={() => setVendorSettingsDivHover(true)}
          >
            <div className='admin-panel-title vendorsettings'>Vendor settings</div>
            <div className='admin-panel-text'>
              <i>Internal use only.</i>
            </div>

            <div className='admin-arrow-link'>
              <Icons.BackArrowRounded fill={vendorSettingsDivHover ? BLACK : GREY} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
