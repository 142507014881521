import { IBreadcrumb } from '@doseme/cohesive-ui'

import { IMatchCrumb } from './types'

export const addPatientRoutePrefix = (route: string, isPatientSubRoute: boolean): string => {
  const patientRoutePrefix = '/patients/:patientId'

  return isPatientSubRoute
    ? patientRoutePrefix + route
    : route
}

export const buildBreadcrumbs = (matchCrumbs: IMatchCrumb[], navigate: any): [IBreadcrumb[], boolean] => {
  let breadcrumbs: IBreadcrumb[] = []
  let loading = false

  matchCrumbs.forEach((matchCrumb) => {
    if (matchCrumb.topLevel) {
      breadcrumbs = []
    }

    if (matchCrumb.parentCrumbs) {
      [breadcrumbs, loading] = buildBreadcrumbs(matchCrumb.parentCrumbs, navigate)
    }

    loading = loading || !!matchCrumb.loading
    breadcrumbs.push({
      label: matchCrumb.label,
      onClick: matchCrumb.path
        ? () => navigate(matchCrumb.path)
        : undefined
    })
  })

  return [breadcrumbs, loading]
}
