import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import useScreenSize from 'use-screen-size'
import { BackArrowButton } from '@doseme/cohesive-ui'

import {
  useAdminHospitalDetailsStore,
  useAdminHospitalDrugDetailsStore,
  useClinicianStore
} from '../../../../../../hooks/useStore'
import { TInlineEditPanels } from './types'
import { GeneralSettingsModal } from './components/GeneralModelSettings/GeneralSettingsModal'
import { DosingReportTemplatesModal } from './components/DosingReportTemplates/DosingReportTemplatesModal'
import { SuperAdminSettingsModal } from './components/SuperadminSettings/SuperAdminSettingsModal'
import { SuperadminSettings } from './components/SuperadminSettings/SuperadminSettings'
import { CalculatedDosesGeneralSettings } from './components/CalculatedDosesGeneralSettings/CalculatedDosesGeneralSettings'
import { CalculatedDosesLimitsAndWarnings } from './components/CalculatedDosesLimitsAndWarnings/CalculatedDosesLimitsAndWarnings'
import { CalculatedDosesTargetSettings } from './components/CalculatedDosesTargetSettings/CalculatedDosesTargetSettings'
import { DosingReportTemplates } from './components/DosingReportTemplates/DosingReportTemplates'
import { GeneralModelSettings } from './components/GeneralModelSettings/GeneralModelSettings'
import { RecordedCourseData } from './components/RecordedCourseData/RecordedCourseData'
import { handleBackButton } from '../../../../../../utils/navigation'
import { showErrorToast } from '../../../../../../shared/toast'

import './index.scss'

export const HospitalDrugDetails: React.FC = observer(() => {
  const [showHospitalDrugModelSettingsModal, setShowHospitalDrugModelSettingsModal] = useState<boolean>(false)
  const [showHospitalDrugReportTemplatesModal, setShowHospitalDrugReportTemplatesModal] = useState<boolean>(false)
  const [showHospitalDrugSuperAdminSettingsModal, setShowHospitalDrugSuperAdminSettingsModal] = useState<boolean>(false)
  const [panelWithEditEnabled, setPanelWithEditEnabled] = useState<TInlineEditPanels>(null)

  const clinicianStore = useClinicianStore()
  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()
  const adminHospitalDrugDetailsStore = useAdminHospitalDrugDetailsStore()
  const size = useScreenSize()

  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const hospitalId = params['hospitalId']!
  const drugId = params['drugId']!

  useEffect(() => {
    adminHospitalDrugDetailsStore.resetStore()

    if (adminHospitalDetailsStore.loadState !== 'loaded' && hospitalId) {
      adminHospitalDetailsStore.fetchAdminHospitalDetails(hospitalId)
    }

    if (hospitalId && drugId) {
      handleFetch()
    }
  }, [hospitalId, drugId])

  const handleFetch = async () => {
    await adminHospitalDrugDetailsStore.fetchAdminHospitalDrugModelDetails(hospitalId, drugId)

    if (['loadError', 'updateError'].includes(adminHospitalDrugDetailsStore.loadState)) {
      showErrorToast(
        adminHospitalDrugDetailsStore.error || 'Failed to load drug settings'
      )
    }
  }

  const drugDetails = adminHospitalDrugDetailsStore.adminHospitalDrugDetails?.attributes.settings

  return (
    <div data-testid='hospital-show' className='co-hospital-show-page'>
      <GeneralSettingsModal
        show={showHospitalDrugModelSettingsModal}
        setShow={setShowHospitalDrugModelSettingsModal}
        hospitalId={hospitalId}
        drugId={drugId}
      />
      <div className='hospital-show-heading'>
        <div className='hospital-show-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() =>
              handleBackButton(
                '../.',
                navigate,
                location
              )
            }
          />
        </div>
        <div className='hospital-show-title'>
          Drug settings: <b>{adminHospitalDrugDetailsStore.adminHospitalDrugDetails?.attributes.name}</b>
        </div>
      </div>
      <GeneralModelSettings
        setShow={setShowHospitalDrugModelSettingsModal}
        show={showHospitalDrugModelSettingsModal}
        data={drugDetails?.general}
      />
      <RecordedCourseData
        width={size.width}
        hospitalId={hospitalId}
        drugModelId={drugId}
        data={drugDetails?.recordedCourseDataLimits}
        setIsPanelEditable={setPanelWithEditEnabled}
        isPanelEditable={panelWithEditEnabled === 'recordedCourseData'}
      />
      <CalculatedDosesGeneralSettings
        width={size.width}
        hospitalId={hospitalId}
        drugModelId={drugId}
        data={drugDetails?.dosesCalculatedGeneralLimits}
        setIsPanelEditable={setPanelWithEditEnabled}
        isPanelEditable={panelWithEditEnabled === 'generalSettings'}
      />
      <CalculatedDosesTargetSettings
        width={size.width}
        hospitalId={hospitalId}
        drugModelId={drugId}
        data={drugDetails?.dosesCalculatedTargetLimits}
        setIsPanelEditable={setPanelWithEditEnabled}
        isPanelEditable={panelWithEditEnabled === 'targetSettings'}
      />
      <CalculatedDosesLimitsAndWarnings
        width={size.width}
        hospitalId={hospitalId}
        drugModelId={drugId}
        data={drugDetails?.dosesCalculatedWarningLimits}
        setIsPanelEditable={setPanelWithEditEnabled}
        isPanelEditable={panelWithEditEnabled === 'limitsWarnings'}
      />
      {clinicianStore.clinician?.attributes.isSuperAdmin && (
        <>
          <DosingReportTemplatesModal
            show={showHospitalDrugReportTemplatesModal}
            setShow={setShowHospitalDrugReportTemplatesModal}
            hospitalId={hospitalId}
            drugId={drugId}
          />

          <DosingReportTemplates
            setShow={setShowHospitalDrugReportTemplatesModal}
            show={showHospitalDrugReportTemplatesModal}
            data={drugDetails?.reportTemplates}
          />

          <SuperAdminSettingsModal
            show={showHospitalDrugSuperAdminSettingsModal}
            setShow={setShowHospitalDrugSuperAdminSettingsModal}
            hospitalId={hospitalId}
            drugId={drugId}
          />

          <SuperadminSettings
            setShow={setShowHospitalDrugSuperAdminSettingsModal}
            show={showHospitalDrugSuperAdminSettingsModal}
            data={drugDetails?.superAdminInfo}
          />
        </>
      )}
    </div>
  )
})
