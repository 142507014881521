import { BasicList, ControlButton, Icons, ListButton, Tag, red100 } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useHospitalStore, usePatientCoursesStore } from '../../../../../../../../hooks/useStore'
import { IRecentAdministrations, IRecentObservations } from '../../../../../../../../store/types'
import { findMostRecentCourse, formatRecentAdmins, formatRecentObservations } from './utils'
import { doseCols, observationCols } from './constants'
import { IProps } from './types'
import { PatientCourse } from '../../../../../../../../store/patientCourses/PatientCourse'

import './index.scss'

export const PatientPreviewPanelBottomSection: React.FC<IProps> = observer(({ selectedPatientId }) => {
  const [mostRecentCourse, setMostRecentCourse] = useState<PatientCourse | null>(null)

  const navigate = useNavigate()
  const hospitalStore = useHospitalStore()
  const patientCoursesStore = usePatientCoursesStore()

  useEffect(() => {
    if (patientCoursesStore.loadState === 'loaded') {
      const newMostRecentCourse = findMostRecentCourse([...patientCoursesStore.courses.values()])
      setMostRecentCourse(newMostRecentCourse)
    } else {
      setMostRecentCourse(null)
    }
  }, [patientCoursesStore.loadState, selectedPatientId])

  const formatAdminAmount = (admin: IRecentAdministrations) => {
    return (
      <span className='d-inline-block'>
        {`${admin.amount.value.toString()} ${admin.amount.unit.name}`}
        {admin.excludeFromCalculations ?
          <div className='d-inline-block pl-1'>
            <Icons.Excluded />
          </div> : null}
      </span>
    )
  }

  const formatObservationLevel = (observation: IRecentObservations): JSX.Element => {
    return (
      <span className='d-inline-block'>
        {`${observation.amount.value.toString()} ${observation.amount.unit.name}`}
        {observation.excludeFromCalculations ?
          <div className='d-inline-block pl-1'>
            <Icons.Excluded />
          </div> : null}
      </span>
    )
  }

  const getAdminObservationContent = () => {
    const recentAdminsContent = mostRecentCourse
      ? formatRecentAdmins(mostRecentCourse, hospitalStore.hospital!.attributes.timezone, formatAdminAmount)
      : []
    const recentObservationsContent = mostRecentCourse
      ? formatRecentObservations(mostRecentCourse, hospitalStore.hospital!.attributes.timezone, formatObservationLevel)
      : []

    return (
      <>
        <div className='patient-preview-admin-obs-header'>Last 3 Doses:</div>
        <div className='patient-preview-admin-obs-details' >
          <BasicList
            cols={doseCols}
            minRowsToShow={3}
            data={recentAdminsContent}
            header={true}
            textIfEmpty='No doses to display.'
            cellClassName='ife-basic-list-cell-height'
          />
        </div>
        <div className='patient-preview-admin-obs-header'>Last 3 Observations:</div>
        <div className='patient-preview-admin-obs-details'>
          <BasicList
            cols={observationCols}
            minRowsToShow={3}
            data={recentObservationsContent}
            header={true}
            textIfEmpty='No observations to display.'
            cellClassName='ife-basic-list-cell-height'
          />
        </div>
      </>
    )
  }

  const linkToPatientProfile = () => {
    const otherCourses = [...patientCoursesStore.courses].length - 1
    const otherCoursesMessage = otherCourses > 0
      ? <span className='patient-preview-link-to-profile-count'>{otherCourses + ' previous courses'}</span>
      : undefined

    return (
      <Link to={`/patients/${selectedPatientId}`}>
        <div className='patient-preview-link-to-profile'>
          {otherCoursesMessage}
          <span className='patient-preview-link-to-profile-text'>View patient profile →</span>
        </div>
      </Link>
    )
  }

  if (!selectedPatientId) {
    return (
      <div className='patient-preview-bottom-wrapper'>
        <div className='preview-patient-bottom-panel-empty' />
      </div>
    )
  }

  if (!mostRecentCourse) {
    return (
      <div className='patient-preview-bottom-wrapper'>
        <div className='preview-patient-bottom-panel-empty'>
          <div className='preview-patient-bottom-panel-empty-text'>
            No existing courses found.
          </div>
          <ControlButton
            size='sm'
            icon={faPlus}
            onClick={() => navigate(`/patients/${selectedPatientId}/courses/new`, { state: { from: '/patients' } })}
          >
            Create new course
          </ControlButton>
        </div>
        {linkToPatientProfile()}
      </div>
    )
  }

  return (
    <div className='patient-preview-bottom-wrapper'>
      <div className='patient-preview-course-preview-top'>
        <div>
          <div className='patient-preview-course-header'>
            Most Recent Course:
            {!mostRecentCourse.attributes.drugModel.isValidated ? (
              <span style={{ transform: 'translateY(-2px)' }} className='ml-2'>
                <Tag size='medium' text='Research' color={red100}/>
              </span>
            ) : null}
          </div>
          {mostRecentCourse.attributes.drugModel.name}
        </div>
        <Link to={`/patients/${selectedPatientId}/courses/${mostRecentCourse.id}`}>
          <ListButton className='course-preview-view-full-course-btn' size='md'>
            View course →
          </ListButton>
        </Link>
      </div>
      <div className='patient-preview-course-preview-bottom'>
        {getAdminObservationContent()}
      </div>
      {linkToPatientProfile()}
    </div>
  )
})
