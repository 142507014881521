import { BLACK, GREY, Icons, IReleaseNote, ReleaseNote, ScrollablePanel } from '@doseme/cohesive-ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useScreenSize from 'use-screen-size'

import { releaseNotes } from './ReleaseNotes'

import './index.scss'

export const Resources: React.FC = () => {
  const [helpDivHover, setHelpDivHover] = useState<boolean>(false)
  const [userDivHover, setUserDivHover] = useState<boolean>(false)
  const [cCDivHover, setCCDivHover] = useState<boolean>(false)
  const [drugDivHover, setDrugDivHover] = useState<boolean>(false)
  const [cRDivHover, setCRDivHover] = useState<boolean>(false)

  const navigate = useNavigate()
  const size = useScreenSize()

  const handleHelpVideosLink = () => {
    navigate('./help-videos')
  }

  const handleUserGuidesLink = () => {
    navigate('./user-guides')
  }

  const handleCCLink = () => {
    navigate('./clinical-competencies')
  }

  const handleDrugInformationLink = () => {
    navigate('./drug-information')
  }

  const handleCRLink = () => {
    navigate('./clinical-resources')
  }

  const handleComplianceLink = () => {
    navigate('./compliance')
  }

  const releaseNotesChildren = (releaseNotes: IReleaseNote[]): JSX.Element[] => {
    return releaseNotes
      .filter((releaseNote) => releaseNote.display !== false)
      .map((releaseNote, key) => {
        return (
          <ReleaseNote
            key={`release-note-${releaseNote.versionNumber}-${key}`}
            latest={key === 0}
            releaseNote={releaseNote}
          />
        )
      })
  }

  const releaseNotesContent = () => {
    if (releaseNotes.length <= 5) {
      return <div className='release-notes-temporary-panel'>{releaseNotesChildren(releaseNotes)}</div>
    }

    return <ScrollablePanel anchorTop>{releaseNotesChildren(releaseNotes)}</ScrollablePanel>
  }

  if (size.width <= 1150) {
    return (
      <div className='co-resource-page'>
        <div className='narrow'>
          <div className='resources-page-title'>DoseMeRx resources and documentation</div>
          <div className='resources-page-panel-block'>
            <div
              data-testid='resources-help-videos'
              className='resources-panel'
              onClick={handleHelpVideosLink}
              onMouseLeave={() => setHelpDivHover(false)}
              onMouseOver={() => setHelpDivHover(true)}
            >
              <div className='resources-panel-title help-videos'>Help videos</div>
              <div className='resources-panel-text'>
                Watch our short how-to videos to assist in getting to know your way around DoseMeRx.
              </div>

              <div data-testid='resources-help-videos-icon' className='resources-arrow-link'>
                <Icons.BackArrowRounded fill={helpDivHover ? BLACK : GREY} />
              </div>
            </div>

            <div
              data-testid='resources-user-guides'
              className='resources-panel ml-4'
              onClick={handleUserGuidesLink}
              onMouseLeave={() => setUserDivHover(false)}
              onMouseOver={() => setUserDivHover(true)}
            >
              <div className='resources-panel-title user-guides'>User guides</div>
              <div className='resources-panel-text'>
                A collection of informative guides to help you understand, and get the most out of DoseMeRx.
              </div>

              <div data-testid='resources-user-guides-icon' className='resources-arrow-link'>
                <Icons.BackArrowRounded fill={userDivHover ? BLACK : GREY} />
              </div>
            </div>
          </div>

          <div className='resources-page-panel-block'>
            <div
              data-testid='resources-clinical-competencies'
              className='resources-panel'
              onClick={handleCCLink}
              onMouseLeave={() => setCCDivHover(false)}
              onMouseOver={() => setCCDivHover(true)}
            >
              <div className='resources-panel-title clinical-competencies'>Clinical competencies</div>
              <div className='resources-panel-text'>
                Interactive PK/PD clinical scenario case studies, each containing walkthroughs, self-assessment questions
                and more.
              </div>

              <div data-testid='resources-clinical-competencies-icon' className='resources-arrow-link'>
                <Icons.BackArrowRounded fill={cCDivHover ? BLACK : GREY} />
              </div>
            </div>

            <div
              data-testid='resources-drug-information'
              className='resources-panel ml-4'
              onClick={handleDrugInformationLink}
              onMouseLeave={() => setDrugDivHover(false)}
              onMouseOver={() => setDrugDivHover(true)}
            >
              <div className='resources-panel-title drug-information'>Drug information</div>
              <div className='resources-panel-text'>
                View information about your available drug models, including hospital limits, dosing information, and
                more.
              </div>

              <div data-testid='resources-drug-information-icon' className='resources-arrow-link'>
                <Icons.BackArrowRounded fill={drugDivHover ? BLACK : GREY} />
              </div>
            </div>
          </div>

          <div className='resources-page-panel-block'>
            <div
              data-testid='resources-clinical-resources'
              className='resources-panel'
              onClick={handleCRLink}
              onMouseLeave={() => setCRDivHover(false)}
              onMouseOver={() => setCRDivHover(true)}
            >
              <div className='resources-panel-title clinical-resources'>Clinical resources</div>
              <div className='resources-panel-text'>
                Resources to assist you with clinical practice and understanding the science behind DoseMeRx.
              </div>

              <div data-testid='resources-clinical-resources-icon' className='resources-arrow-link'>
                <Icons.BackArrowRounded fill={cRDivHover ? BLACK : GREY} />
              </div>
            </div>

            {window.env.VENDOR_MODE === 'standalone' ? (
              <div
                data-testid='resources-compliance'
                className='resources-panel ml-4'
                onClick={handleComplianceLink}
                onMouseLeave={() => setCRDivHover(false)}
                onMouseOver={() => setCRDivHover(true)}
              >
                <div className='resources-panel-title compliance'>Compliance</div>
                <div className='resources-panel-text'>
                  Download our Instructions for Use (IFU), and other available compliance documentation.
                </div>

                <div data-testid='resources-compliance-icon' className='resources-arrow-link'>
                  <Icons.BackArrowRounded fill={cRDivHover ? BLACK : GREY} />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div data-testid='release-notes' className='release-notes'>
          <div className='release-notes-title'>What's New</div>
          <div className='release-notes-container'>{releaseNotesContent()}</div>
        </div>
      </div>
    )
  }

  return (
    <div className='co-resource-page'>
      <div className='resources-page-title'>DoseMeRx resources and documentation</div>
      <div className='resources-page-panel-block'>
        <div
          data-testid='resources-help-videos'
          className='resources-panel'
          onClick={handleHelpVideosLink}
          onMouseLeave={() => setHelpDivHover(false)}
          onMouseOver={() => setHelpDivHover(true)}
        >
          <div className='resources-panel-title help-videos'>Help videos</div>
          <div className='resources-panel-text'>
            Watch our short how-to videos to assist in getting to know your way around DoseMeRx.
          </div>

          <div data-testid='resources-help-videos-icon' className='resources-arrow-link'>
            <Icons.BackArrowRounded fill={helpDivHover ? BLACK : GREY} />
          </div>
        </div>

        <div
          data-testid='resources-user-guides'
          className='resources-panel ml-4'
          onClick={handleUserGuidesLink}
          onMouseLeave={() => setUserDivHover(false)}
          onMouseOver={() => setUserDivHover(true)}
        >
          <div className='resources-panel-title user-guides'>User guides</div>
          <div className='resources-panel-text'>
            A collection of informative guides to help you understand, and get the most out of DoseMeRx.
          </div>

          <div data-testid='resources-user-guides-icon' className='resources-arrow-link'>
            <Icons.BackArrowRounded fill={userDivHover ? BLACK : GREY} />
          </div>
        </div>

        <div
          data-testid='resources-clinical-competencies'
          className='resources-panel ml-4'
          onClick={handleCCLink}
          onMouseLeave={() => setCCDivHover(false)}
          onMouseOver={() => setCCDivHover(true)}
        >
          <div className='resources-panel-title clinical-competencies'>Clinical competencies</div>
          <div className='resources-panel-text'>
            Interactive PK/PD clinical scenario case studies, each containing walkthroughs, self-assessment questions
            and more.
          </div>

          <div data-testid='resources-clinical-competencies-icon' className='resources-arrow-link'>
            <Icons.BackArrowRounded fill={cCDivHover ? BLACK : GREY} />
          </div>
        </div>
      </div>

      <div className='resources-page-panel-block'>
        <div
          data-testid='resources-drug-information'
          className='resources-panel'
          onClick={handleDrugInformationLink}
          onMouseLeave={() => setDrugDivHover(false)}
          onMouseOver={() => setDrugDivHover(true)}
        >
          <div className='resources-panel-title drug-information'>Drug information</div>
          <div className='resources-panel-text'>
            View information about your available drug models, including hospital limits, dosing information, and more.
          </div>

          <div data-testid='resources-drug-information-icon' className='resources-arrow-link'>
            <Icons.BackArrowRounded fill={drugDivHover ? BLACK : GREY} />
          </div>
        </div>

        <div
          data-testid='resources-clinical-resources'
          className='resources-panel ml-4'
          onClick={handleCRLink}
          onMouseLeave={() => setCRDivHover(false)}
          onMouseOver={() => setCRDivHover(true)}
        >
          <div className='resources-panel-title clinical-resources'>Clinical resources</div>
          <div className='resources-panel-text'>
            Resources to assist you with clinical practice and understanding the science behind DoseMeRx.
          </div>

          <div data-testid='resources-clinical-resources-icon' className='resources-arrow-link'>
            <Icons.BackArrowRounded fill={cRDivHover ? BLACK : GREY} />
          </div>
        </div>

        {window.env.VENDOR_MODE === 'standalone' ? (
          <div
            data-testid='resources-compliance'
            className='resources-panel ml-4'
            onClick={handleComplianceLink}
            onMouseLeave={() => setCRDivHover(false)}
            onMouseOver={() => setCRDivHover(true)}
          >
            <div className='resources-panel-title compliance'>Compliance</div>
            <div className='resources-panel-text'>
              Download our Instructions for Use (IFU), and other available compliance documentation.
            </div>

            <div data-testid='resources-compliance-icon' className='resources-arrow-link'>
              <Icons.BackArrowRounded fill={cRDivHover ? BLACK : GREY} />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div data-testid='release-notes' className='release-notes'>
        <div className='release-notes-title'>What's New</div>
        <div className='release-notes-container'>{releaseNotesContent()}</div>
      </div>
    </div>
  )
}
