import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import classnames from 'classnames'
import { IMapEntry } from 'mobx'
import { Button, Icons, InfoButton, InfoModal, Modal, TextInput } from '@doseme/cohesive-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import {
  useApplicableDrugModelsStore,
  useCourseStore
} from '../../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { IApplicableDrugModel } from '../../../../../../../../store/applicableDrugModels/types'
import { IProps } from './types'
import { ModelSelectionPopupModal } from './components/ModelSelectionPopupModal'

import './index.scss'

export const DrugSwitchModal: React.FC<IProps> = observer((props) => {
  const courseStore = useCourseStore()
  const applicableDrugModelsStore = useApplicableDrugModelsStore()

  const [isChecked, setIsChecked] = useState(false)
  const [selectedDrugModel, setSelectedDrugModel] = useState<IApplicableDrugModel | null>(null)
  const [showUnapplicableModels, setShowUnapplicableModels] = useState(false)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (props.show) {
      applicableDrugModelsStore.fetchApplicableDrugModels(props.patientId, props.course.id)
      setIsChecked(false)
      setSelectedDrugModel(null)
    }
  }, [props.show])

  const handleSubmit = async () => {
    if (selectedDrugModel) {
      const result = await courseStore.editCourse(props.patientId, props.course.id, selectedDrugModel.id)
      if (!result || courseStore.loadState === 'updateError') {
        showErrorToast(courseStore.error || 'Failed to update course')

        return
      }

      showSuccessToast('Course updated')
      window.location.reload()
      props.setShow(false)
    }
  }

  const drugSwitchModalWarningMessage = (): JSX.Element => {
    const warningCheckBox = (
      <div className='d-flex'>
        <input
          name='confirm-model-switch'
          id='confirm-model-switch'
          data-testid='drugswitchmodal-checkbox'
          className='mt-4'
          type='checkbox'
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
        />
        <label htmlFor='confirm-model-switch' className='drugmodelswitch-checkbox-text'>
          I understand, continue.
        </label>
      </div>
    )

    if (selectedDrugModel && selectedDrugModel.attributes.warning && courseStore.course) {
      return (
        <>
          <div className='drugmodelswitch-warning'>
            <Icons.Alert />
            <div className='drugmodelswitch-warning-text'>
              <span className='font-14-weight-700 '>Warning: </span>
              <span className='drugmodelswitch-warning-message'>{selectedDrugModel?.attributes.warning}</span>
            </div>
          </div>
          {warningCheckBox}
        </>
      )
    }

    return (
      <>
        <div className='drugmodelswitch-warning'>
          <Icons.Alert />
          <div className='drugmodelswitch-warning-text'>
            <span className='font-14-weight-700 '>Warning: </span>
            <span className='drugmodelswitch-warning-message'>
              Updating the drug model associated with this course may impact the current dose recommendations
              as well as the historical plot, and predicted outcomes for previous doses.
            </span>
          </div>
        </div>
        {warningCheckBox}
      </>
    )
  }

  const displayUnapplicableModels = (unapplicableModelItems: IMapEntry<string, IApplicableDrugModel>[]): JSX.Element[] => {
    const returnValue = unapplicableModelItems.map(([key, drugModel]) => {
      return (
        <div key={`${drugModel.id}-${key}`} className='unapplicable-models-item'>
          <div className='font-weight-bold'>
            {drugModel.attributes.name}
            {drugModel.attributes.errors &&
              drugModel.attributes.errors.map((error) => (
                <div key={`key-${drugModel.id}-${error}`} className='unapplicable-models-item-error'>
                  <ul>
                    <li>{error}</li>
                  </ul>
                </div>
              ))}
          </div>
        </div>
      )
    })

    return returnValue
  }

  const displayUnapplicableModelsDiv = (): JSX.Element => {
    const unapplicableModels = [...applicableDrugModelsStore.applicableDrugModels].filter(([key, drugModel]) => {
      if (!drugModel.attributes.valid) {
        return drugModel
      }
    })

    if (unapplicableModels.length > 0) {
      return (
        <div
          onClick={() => setShowUnapplicableModels(!showUnapplicableModels)}
          className={classnames('unapplicable-models-outer', { closed: !showUnapplicableModels })}
        >
          <div className='d-flex'>
            <div className='unapplicable-models-title'>Unsuitable models</div>
            <div className='unapplicable-models-chevron-outer'>
              <div className='mr-2'>{showUnapplicableModels ? 'Hide' : 'Show'}</div>
              <div
                className={classnames('unapplicable-models-chevron', {
                  activated: showUnapplicableModels
                })}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>
          </div>
          <div>{showUnapplicableModels && displayUnapplicableModels(unapplicableModels)}</div>
        </div>
      )
    }

    return <></>
  }

  const displayApplicableModels = (): JSX.Element[] | JSX.Element => {
    const modelFitRanks = applicableDrugModelsStore.getModelFitRank()
    const isBetterModels = modelFitRanks.length > 0 ? true : false

    const applicableModels = [...applicableDrugModelsStore.applicableDrugModels].filter(([key, drugModel]) => {
      if (drugModel.attributes.valid && !drugModel.attributes.modelFitRank) {
        return drugModel
      }
    })

    if (applicableModels.length < 1) {
      return <div className='no-applicable-models'>No suitable models for this patient.</div>
    }

    return (
      <div className={classnames('applicable-models-border mt-3', { 'better-models': isBetterModels })}>
        {isBetterModels && (
          <div className=' mb-4'>
            <div className='font-16-weight-700'>Models that may be better suited to this patient:</div>
            <div className='model-selection-better-model-subtitle'>
              Ranked by <div className='model-selection-underline'>&nbsp;model suitability&nbsp;</div> based on existing
              course data.
            </div>
            {modelFitRanks.map((drugModel, key) => (
              <div key={`${drugModel.id}-${key}`} className='d-flex'>
                <div
                  className={classnames('better-model-selection-box', {
                    selected: selectedDrugModel && drugModel.id === selectedDrugModel.id
                  })}
                >
                  {key + 1}
                </div>
                <div
                  onClick={() => setSelectedDrugModel(drugModel)}
                  className={classnames('selectable-checkbox add-course-content-panel-drugswitchmodal mt-2', {
                    'add-course-model-selection-selected': selectedDrugModel && drugModel.id === selectedDrugModel.id
                  })}
                >
                  <input
                    type='checkbox'
                    id='checkbox-round'
                    readOnly
                    checked={(selectedDrugModel && drugModel.id === selectedDrugModel.id) || undefined}
                  />
                  <div className='add-course-model-text'>{drugModel.attributes.name}</div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className='font-16-weight-700'>
          {isBetterModels ? 'Select from other applicable models:' : 'Other applicable models:'}
        </div>
        {applicableModels
          .filter(([key, drugModel]) => drugModel.id !== props.drugModelId)
          .map(([key, drugModel]) => (
            <div
              key={`${drugModel.id}-${key}`}
              onClick={() => setSelectedDrugModel(drugModel)}
              className={classnames('selectable-checkbox add-course-content-panel-drugswitchmodal mt-2', {
                'add-course-model-selection-selected': selectedDrugModel && drugModel.id === selectedDrugModel.id
              })}
            >
              <input
                type='checkbox'
                id='checkbox-round'
                readOnly
                checked={selectedDrugModel && drugModel.id === selectedDrugModel.id || undefined}
              />
              <div className='add-course-model-text'>{drugModel.attributes.name}</div>
            </div>
          ))}
      </div>
    )
  }

  const formContent: JSX.Element = (
    <div className='change-drug-model-form'>
      <div className='applicable-models-border'>
        <div className='font-16-weight-700'>Current course model:</div>
        <TextInput type='text' fieldState='valid' value={props.course.attributes.drugModel.name} disabled={true} />
      </div>
      <div className='w-100'>
        {displayApplicableModels()}
        {displayUnapplicableModelsDiv()}
      </div>

      {selectedDrugModel && props.drugModelId !== selectedDrugModel.id && drugSwitchModalWarningMessage()}
    </div>
  )

  if (applicableDrugModelsStore.loadState === 'loaded') {
    return (
      <>
        <div className='model-selection-popup-modal'>
          <ModelSelectionPopupModal show={show} setShow={setShow} />
        </div>
        <div className='model-selection-modal'>
          <Modal
            show={props.show}
            onHide={() => {
              props.setShow(false)
              setIsChecked(false)
            }}
          >
            <InfoModal
              size='s'
              linkComponent={
                <>
                  <Button
                    className='mr-3'
                    data-testid='cancel-btn'
                    onClick={() => props.setShow(false)}
                    variant='secondary-outline'
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={courseStore.loadState === 'loading'}
                    disabled={
                      !isChecked ||
                      (selectedDrugModel && props.drugModelId === selectedDrugModel.id) ||
                      courseStore.loadState === 'updating' ||
                      applicableDrugModelsStore.loadState !== 'loaded'
                    }
                    onClick={handleSubmit}
                    variant='primary'
                  >
                    Update Course
                  </Button>
                </>
              }
              title={
                <div className='d-flex'>
                  <div className='d-flex'>
                    <div className='mr-2'>Model Selection</div>
                    {applicableDrugModelsStore.getModelFitRank().length > 0 && <Icons.Star width={28} height={28} />}
                  </div>
                  <div className='model-selection-info-text'>
                    How is this calculated?
                    <div className='model-selection-info-icon'>
                      <InfoButton testId='model-fit-info-tooltip' onClick={() => setShow(true)} />
                    </div>
                  </div>
                </div>
              }
              message={formContent}
              limitHeightToWindow
            />
          </Modal>
        </div>
      </>
    )
  }

  return <></>
})
