import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ActionButton, BackArrowButton, Button, Icons, ListButton, Spinner, TextInput } from '@doseme/cohesive-ui'

import { ImportPatientModal } from './ImportPatientModal'
import { usePatientStore, useHospitalStore, useClinicianStore, useAuthStore } from '../../../../hooks/useStore'
import { buildDateInput, buildFormFields, buildRadioGroup, buildSelectionInput, buildTextInput } from '../../../../shared/buildForms'
import { useFormValidation } from '../../../../hooks/useFormValidation'
import { IPostAddPatient } from '../../../../store/patient/types'
import { showErrorToast, showSuccessToast } from '../../../../shared/toast'
import { handleBackButton } from '../../../../utils/navigation'

import './index.scss'

export const AddPatient: React.FC = observer(() => {
  const [alreadyExists, setAlreadyExists] = useState(false)
  const [showImportPatientModal, setShowImportPatientModal] = useState(false)

  const patientStore = usePatientStore()
  const hospitalStore = useHospitalStore()
  const clinicianStore = useClinicianStore()
  const authStore = useAuthStore()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (hospitalStore.loadState === 'loaded' && hospitalStore.hospital?.id) {
      patientStore.fetchAddPatient(hospitalStore.hospital?.id)
    }
  }, [hospitalStore.loadState])

  const formFields = useMemo(() => {
    if (patientStore.loadState === 'loaded' && patientStore.addPatient) {
      return buildFormFields(patientStore.addPatient)
    }

    return {}
  }, [patientStore.loadState])

  useEffect(() => {
    if (clinicianStore.loadState === 'initial' && authStore.auth) {
      clinicianStore.fetchClinician(authStore.auth.attributes.clinicianId)
    }
  }, [])

  const form = useFormValidation(formFields)

  useEffect(() => {
    if (patientStore.loadState === 'loaded') {
      form.reset()
    }
  }, [patientStore.loadState, formFields])

  const handleLongIdBlur = async () => {
    const newLongId = form.inputs['longid']?.trim()
    if (hospitalStore.loadState === 'loaded' && hospitalStore.hospital?.id
      && newLongId && newLongId !== '') {
      const existingPatient = await patientStore.searchHospitalPatient(hospitalStore.hospital?.id, form.inputs['longid'])
      setAlreadyExists(!!existingPatient)
    }

    form.updateFieldsDisplay(['longid'])
  }

  const formatLongIdField = () => {
    const longid = patientStore.addPatient?.attributes.longid
    const validState = alreadyExists && patientStore.longIdLoadState === 'loaded'
      ? 'error'
      : form.getValidState('longid')
    const validationText = alreadyExists && patientStore.longIdLoadState === 'loaded'
      ? 'ID already exists'
      : form.getValidationMsg('longid')
    const statusIcon = patientStore.longIdLoadState === 'loading'
      ? <Spinner />
      : alreadyExists ? <Icons.Alert /> : undefined

    return (
      <div className='add-patient-form-row'>
        <div className='add-patient-form-col-left'>
          <TextInput
            label={longid?.attributes.label}
            fieldState={validState}
            validationText={validationText}
            value={form.inputs['longid'] || ''}
            name='longid'
            required={longid?.attributes.isRequired}
            onChange={(value) =>
              form.validateFields([
                {
                  field: 'longid',
                  input: value,
                  constraints: formFields['longid'].initialConstraints
                }
              ])
            }
            onBlur={handleLongIdBlur}
            validityIcon={statusIcon}
          />
        </div>
        {alreadyExists &&
          <div className='add-patient-form-col-right'>
            <Link to={`/patients/${patientStore.patient?.id}`}>
              <ListButton className='add-patient-form-view-existing' size='md'>
                View existing patient →
              </ListButton>
            </Link>
          </div>
        }
      </div>
    )
  }

  const formContent = (): JSX.Element => {
    if (patientStore.addPatient) {
      return (
        <div className='position-relative w-100'>
          {formatLongIdField()}
          <div className='add-patient-form-row'>
            <div className='add-patient-form-col-left'>
              {buildTextInput(patientStore.addPatient.attributes.lastName, form, formFields['lastName'], 'lastName')}
            </div>
            <div className='add-patient-form-col-right'>
              {buildTextInput(patientStore.addPatient.attributes.firstName, form, formFields['firstName'], 'firstName')}
            </div>
          </div>
          <div className='add-patient-form-row'>
            <div className='add-patient-form-col-left'>
              {buildDateInput(patientStore.addPatient.attributes.dob, form, 'dob')}
            </div>
            <div className='add-patient-form-col-right'>
              {buildRadioGroup(patientStore.addPatient.attributes.sex, form, 'sex')}
            </div>
          </div>
          <div className='add-patient-form-row'>
            <div className='add-patient-form-col-left'>
              {buildTextInput(patientStore.addPatient.attributes.weight, form, formFields['weight'], 'weight')}
            </div>
            <div className='add-patient-form-col-right'>
              {buildTextInput(patientStore.addPatient.attributes.height, form, formFields['height'], 'height')}
            </div>
          </div>
          {buildSelectionInput(patientStore.addPatient.attributes.ward, form, 'ward')}
        </div>
      )
    }

    return <></>
  }

  const submitPatient = async (createCourse?: boolean) => {
    const heightUnits = typeof patientStore.addPatient?.attributes.height.attributes.units !== 'string'
      ? patientStore.addPatient?.attributes.height.attributes.units
      : null

    const weightUnits = typeof patientStore.addPatient?.attributes.weight.attributes.units !== 'string'
      ? patientStore.addPatient?.attributes.weight.attributes.units
      : null

    const newPatient: IPostAddPatient = {
      type: 'hospitalPatientNewWrite',
      attributes: {
        firstName: form.values['firstName'],
        lastName: form.values['lastName'],
        longid: form.values['longid'],
        dob: form.values['dob'],
        sex: form.values['sex'],
        height: {
          value: form.values['height'],
          unit: heightUnits
        },
        weight: {
          value: form.values['weight'],
          unit: weightUnits
        },
        ward: form.values['ward'] ? parseInt(form.values['ward']) : null
      }
    }

    let result
    if (hospitalStore.hospital?.id) {
      result = await patientStore.postAddPatient(hospitalStore.hospital?.id, newPatient)
    }

    if (!result || patientStore.loadState === 'updateError') {
      showErrorToast(patientStore.error || 'Failed to add patient')

      return
    }

    showSuccessToast('Patient added')

    if (createCourse) {
      navigate(`/patients/${patientStore.patient?.id}/courses/new`, { state: { from: '/patients' } })
    } else {
      navigate('/patients')
    }
  }

  const saveButtonsDisabled = !form.valid || alreadyExists || !['loaded', 'updateError'].includes(patientStore.loadState)
    || !['loaded', 'updateError'].includes(patientStore.longIdLoadState)

  return (
    <div data-testid='add-patient' className='co-add-patient-page'>
      <div className='add-patient-heading'>
        <div className='add-patient-back-btn'>
          <BackArrowButton data-testid='back-btn' onClick={() => handleBackButton('/patients', navigate, location)} />
        </div>
        <div className='add-patient-title'>Add new patient</div>
      </div>
      <div className='add-patient-content-panel'>
        <div className='patient-details-title-and-import-patient-button'>
          <div className='add-patient-content-title'>Patient details</div>
          {clinicianStore.clinician?.attributes.isSuperAdmin &&
              <div>
                <div className='import-patient-btn'>
                  <ActionButton data-testid='import-patient-btn' actionType='import'
                    onClick={() => setShowImportPatientModal(true)} customLabel='Import patient from file'/>
                </div>
                <ImportPatientModal show={showImportPatientModal} setShow={setShowImportPatientModal}/>
              </div>
          }
        </div>
        <div className='add-patient-inputs-wrapper'>
          {formContent()}
          <div className='add-patient-button-row'>
            <Button
              disabled={saveButtonsDisabled}
              loading={['loading', 'updating'].includes(patientStore.loadState)}
              onClick={() => submitPatient(true)}
              variant='primary'
              className='add-patient-button'
            >
              Continue to create course
            </Button>
            <Button
              disabled={saveButtonsDisabled}
              loading={['loading', 'updating'].includes(patientStore.loadState)}
              onClick={() => submitPatient()}
              variant='primary-outline'
            >
              Save patient
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
})
