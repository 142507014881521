import { Modal, InfoModal, InfoBubble } from '@doseme/cohesive-ui'

import { IModalProps } from '../../../../../../../../types'
import { svgAllApplicableModels, svgSuggestedModels } from './constants'

import './index.scss'

export const ModelSelectionPopupModal: React.FC<IModalProps> = (props) => {
  const getModalContent = (): JSX.Element => {
    return (
      <div className='model-fit-modal'>
        <div className='model-fit-text-div'>
          <div className='model-fit-subheading'>How does DoseMeRx suggest a model?</div>
          <p className='model-fit-paragraph'>
            For supported drugs, you will see a suggested model when there is more than one model applicable for a
            patient.
          </p>
          <p className='model-fit-paragraph'>
            DoseMeRx compares the patient’s demographic information to the population sample used by the drug model to
            determine the suitability of each drug model for that patient. The most suitable model will appear at the
            top as the suggested model, with all other applicable models listed below.
          </p>
          <p className='model-fit-paragraph'>
            You can choose to continue with the suggested model, or select any applicable model you believe is most
            appropriate based on your clinical judgment.
          </p>
          {svgSuggestedModels}
        </div>
        {infoBubble()}
        <div className='model-fit-text-div'>
          <div className='model-fit-subheading'>What if there is no suggested model?</div>
          <p className='model-fit-paragraph'>
            If there is no suggested model, it means that DoseMeRx is unable to determine suitability for any applicable
            model for that drug. All applicable models will be listed for you to select the most appropriate one, based
            on your clinical judgment.
          </p>
          {svgAllApplicableModels}
        </div>
        <p className='model-fit-bottom-text'>
          For more information, head over to the Resources section or contact the DoseMeRx support team directly - we're
          avaiable 24/7.
        </p>
      </div>
    )
  }

  const infoBubble = () => {
    const title = <div className='model-fit-subheading'>Important information:</div>

    return (
      <InfoBubble bubbleTitle={title}>
        <p className='model-fit-paragraph-infobubble'>
          A model’s suitability is determined by comparing a patient’s demographic information to the model population
          before the course has been individualized.
        </p>
        <p className='model-fit-paragraph-infobubble'>
          Once more course information is available (i.e. recorded doses and
          observations), the model’s suitability should be re-evaluated based on individualized model fit.
        </p>
      </InfoBubble>
    )
  }

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <InfoModal
        size='m'
        title={<div>Model selection information: <br/> Edit course model</div>}
        message={getModalContent()}
        onDismiss={() => props.setShow(false)}
        limitHeightToWindow
      />
    </Modal>
  )
}
