import { Accordion, BackArrowButton, FloatingTabBar, Icons, LOADING_GREY } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'

import { useDrugModelsInformationStore } from '../../../../hooks/useStore'
import { IDrugModelInformation, IHospitalLimits } from '../../../../store/drugModelsInformation/types'
import { receiveEpicToken, requestEpicHandshake, openExternalWindowEpic } from '../../../OpenWindowEpic'
import { handleBackButton } from '../../../../utils/navigation'

import './index.scss'

interface IProps {
  hospitalId: string
}

interface IDrugMap {
  [key: string]: IDrugModelInformation[]
}

export const DrugInformation: React.FC<IProps> = observer((props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const patientId = params['patientId']

  const shouldIncludePatientSpecificTab = window.env.VENDOR_MODE !== 'standalone' && patientId
  const [activeTab, setActiveTab] = useState<string>(shouldIncludePatientSpecificTab ? 'patient-specific' : 'all')
  const [token, setToken] = useState<string>()

  const drugModelsInformationStore = useDrugModelsInformationStore()

  useEffect(() => {
    if (window.env.VENDOR_MODE === 'epic') {
      requestEpicHandshake(receiveEpicToken, setToken)
    }
  }, [])

  useEffect(() => {
    drugModelsInformationStore.fetchDrugModelsInformation(props.hospitalId, patientId)
  }, [])

  const epicClickHandler = (e: any) => {
    if (e.target.nodeName === 'A') {
      e.preventDefault()
      const link = e.target.href.toString()
      openExternalWindowEpic(link, token)
    }
  }

  const deconstructHrefs = (desc: string): string => {
    return desc.replace(
      // We use [^\x05] instead of . in this regex as we need ignore newlines \n
      // We cannot use the s flag as one normally would to do this, as support was added from ES2018 onwards.
      // https://bugzilla.mozilla.org/show_bug.cgi?id=1579867#c1
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Bad_regexp_flag
      // @ts-ignore
      /<a[^\x05]*?href="([^\\x05]*?)"[^\x05]*?>([^\x05]*?)(?:<i[^\x05]*?>[^\x05]*?<\/i>)*?<\/a>/gi,
      (match, p1: string, p2: string) => {
        const link = p1.trim()
        const text = p2.trim()

        return link === text ? link : `${text} [${link}]`
      }
    )
  }

  const filterDrugModels = () => {
    const drugModelsArr = [...drugModelsInformationStore.drugModels].map(([key, item]) => item)
    drugModelsArr.sort((a, b) =>
      a.attributes.name < b.attributes.name ? -1 : a.attributes.name > b.attributes.name ? 1 : 0
    )

    if (activeTab === 'patient-specific') {
      return drugModelsArr.filter((item) => item.attributes.patientSpecific)
    }

    return drugModelsArr
  }

  const accordionParentData = () => {
    const drugInformationDataFilteredTab = filterDrugModels()
    const drugInformationArray = drugInformationDataFilteredTab.reduce<IDrugMap>((acc, curr) => {
      if (acc[curr.attributes.modelGroup]) {
        acc[curr.attributes.modelGroup].push(curr)

        return acc
      }
      acc[curr.attributes.modelGroup] = [curr]

      return acc
    }, {})

    return Object.keys(drugInformationArray).map((modelGroup) => {
      return (
        <div key={`drug-info-${modelGroup}`} className='drug-information-accordion'>
          <Accordion
            type='parent'
            title={modelGroup}
            childElements={accordionChildData(drugInformationArray[modelGroup])}
          />
        </div>
      )
    })
  }

  const dosingTargetEnabledFormattedList = (hLimits: IHospitalLimits) => {
    return hLimits.dosingTargetsEnabled.map(targetEnabled => (
      <li key={`target-enabled-list-${targetEnabled.name}`}>
        <strong>{targetEnabled.name}: </strong>
        {targetEnabled.enabled ? 'Yes' : 'No'}
      </li>
    ))
  }

  const accordionChildData = (drugModelArray: IDrugModelInformation[]) => {
    return drugModelArray.map((curr) => {
      const hLimits = curr.attributes.hospitalLimits

      return {
        title: curr.attributes.name,
        data: (
          <div className='accordion-child-data-wrapper'>
            <div
              data-testid='drug-info-accordian'
              onClick={window.env.VENDOR_MODE === 'epic' ? epicClickHandler : undefined}
              dangerouslySetInnerHTML={{
                __html: deconstructHrefs(curr.attributes.description)
              }}
            />
            <h2>Hospital Limits</h2>
            <p>
              The following limits have been configured by your hospital. Please speak to your administrator or DoseMeRx
              support if you wish these altered for your hospital.
            </p>
            <h4>Dose Calculation Methods</h4>
            <ul>
              <li>
                <strong>Individualized: </strong>
                {hLimits.doseCalculationMethods.individualized
                  ? hLimits.doseCalculationMethods.individualized
                  : 'No guideline is configured at your hospital.'}
              </li>
              <li>
                <strong>Guideline: </strong>
                {hLimits.doseCalculationMethods.guideline
                  ? hLimits.doseCalculationMethods.guideline
                  : 'No guideline is configured at your hospital.'}
              </li>
              <li>
                <strong>Label: </strong>
                {hLimits.doseCalculationMethods.label
                  ? hLimits.doseCalculationMethods.label
                  : 'No guideline is configured at your hospital.'}
                <br />
                <strong>Warning:</strong> Non-label doses may not have been verified as appropriate for clinical use by
                the FDA.
              </li>
            </ul>
            <h4>Dosing Targets Enabled</h4>
            <ul>
              {dosingTargetEnabledFormattedList(hLimits)}
            </ul>
            <h4>Patient Limits</h4>
            <ul>
              <li>
                <strong>Age: </strong>
                {`${hLimits.patientLimits.age.min.value} - ${hLimits.patientLimits.age.max.value} ${hLimits.patientLimits.age.max.unit?.name}`}
              </li>
              <li>
                <strong>Height: </strong>
                {`${hLimits.patientLimits.height.min.value} - ${hLimits.patientLimits.height.max.value} ${hLimits.patientLimits.height.max.unit?.name}`}
              </li>
              <li>
                <strong>Weight: </strong>
                {`${hLimits.patientLimits.weight.min.value} - ${hLimits.patientLimits.weight.max.value} ${hLimits.patientLimits.weight.max.unit?.name}`}
              </li>
            </ul>
            <div
              onClick={window.env.VENDOR_MODE === 'epic' ? epicClickHandler : undefined}
              dangerouslySetInnerHTML={{
                __html: deconstructHrefs(curr.attributes.dosingInfo)
              }}
            />
            <div
              onClick={window.env.VENDOR_MODE === 'epic' ? epicClickHandler : undefined}
              dangerouslySetInnerHTML={{
                __html: deconstructHrefs(curr.attributes.officialInfo)
              }}
            />
          </div>
        )
      }
    })
  }

  const getTabs = () => {
    if (shouldIncludePatientSpecificTab) {
      return (
        <div className='drug-information-floating-tab-bar'>
          <FloatingTabBar
            tabNames={[
              { id: 'patient-specific', displayName: 'Patient specific drugs' },
              { id: 'all', displayName: 'All drug models' }
            ]}
            onSelectTab={setActiveTab}
            activeTab={activeTab}
            disabled={drugModelsInformationStore.loadState !== 'loaded'}
          />
        </div>
      )
    }

    return null
  }

  return (
    <div data-testid='drug-information' className='co-resource-page'>
      <div className='d-flex'>
        <div className='resource-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() => handleBackButton('../.', navigate, location)}
          />
        </div>
        <div className='resource-title drug-information'>Drug Information</div>
      </div>

      <div className='resource-panel'>
        {getTabs()}

        <div className='resource-panel-title'>
          {window.env.VENDOR_MODE === 'standalone'
            ? 'Drug information for all models available in DoseMeRx at this site.'
            : 'Drug information for all models associated with drug molecules for existing courses found for this patient.'}
        </div>
        <div>
          {drugModelsInformationStore.loadState === 'loading' && (
            <div className='drug-information-spinner'>
              <Icons.ThinSpinner strokeWidth={15} r={35} stroke={LOADING_GREY} width='70px' />
            </div>
          )}
          {drugModelsInformationStore.loadState === 'loaded' &&
            drugModelsInformationStore.drugModels &&
            accordionParentData()}
        </div>
      </div>
    </div>
  )
})
