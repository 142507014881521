import { ADE, BackArrowButton, Icons } from '@doseme/cohesive-ui'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { receiveEpicToken, requestEpicHandshake } from '../../../OpenWindowEpic'
import { formatExternalLinkDivs, downloadDocumentURL } from '../../utils'
import { handleBackButton } from '../../../../utils/navigation'

import '../index.scss'

export const ClinicalCompetencies: React.FC = () => {
  const [vanc1DivHover, setVanc1DivHover] = useState<boolean>(false)
  const [vanc2DivHover, setVanc2DivHover] = useState<boolean>(false)
  const [vancAdultDivHover, setVancAdultDivHover] = useState<boolean>(false)
  const [vancPedDivHover, setVancPedDivHover] = useState<boolean>(false)
  const [token, setToken] = useState<string>()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (window.env.VENDOR_MODE === 'epic') {
      requestEpicHandshake(receiveEpicToken, setToken)
    }
  }, [])

  return (
    <div data-testid='clinical-competencies' className='co-resource-page'>
      <div className='d-flex'>
        <div className='resource-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() => handleBackButton('../.', navigate, location)}
          />
        </div>
        <div className='resource-title clinical-competencies'>Clinical Competencies</div>
      </div>

      <div className='resource-panel'>
        <div className='resource-panel-title'>
          Developed by DoseMeRx, our clinical competencies are a collection of interactive PK/PD clinical case
          scenarios, each containing walk-throughs, guidance, self-assessment questions and more.
        </div>

        {formatExternalLinkDivs(
          downloadDocumentURL('/DoseMeRx_Vancomycin_1-Compartment_Adult_Clinical_Competency.pptx'),
          token,
          <div
            data-testid='vanc-adult-1'
            className='download-link-div'
            onMouseLeave={() => setVanc1DivHover(false)}
            onMouseOver={() => setVanc1DivHover(true)}
          >
            <div className='link-div-wrapper'>
              <div className='doseme-link-div-title mb-2'>Vancomycin Adult 1-compartment model kit</div>
              <div className='doseme-link-div-text'>
                Ideal for individualized dosing from just one drug concentration result.
              </div>
            </div>
            <div data-testid='vanc-adult-1-icon' className='download-link-div-import-icon'>
              <Icons.Import width={20} height={20} background={vanc1DivHover ? ADE : undefined} />
            </div>
          </div>
        )}

        {formatExternalLinkDivs(
          downloadDocumentURL('/DoseMeRx_Vancomycin_2-Compartment_Adult_Clinical_Competency.pptx'),
          token,
          <div
            data-testid='vanc-adult-2'
            className='download-link-div'
            onMouseLeave={() => setVanc2DivHover(false)}
            onMouseOver={() => setVanc2DivHover(true)}
          >
            <div className='link-div-wrapper'>
              <div className='doseme-link-div-title mb-2'>Vancomycin Adult 2-compartment model kit</div>
              <div className='doseme-link-div-text'>
                Accurate estimation of AUC fitting to both distribution and elimination phases. Ideal for complex and/or
                critically ill patients where multiple levels are being obtained.
              </div>
            </div>
            <div data-testid='vanc-adult-2-icon' className='download-link-div-import-icon'>
              <Icons.Import width={20} height={20} background={vanc2DivHover ? ADE : undefined} />
            </div>
          </div>
        )}

        {formatExternalLinkDivs(
          downloadDocumentURL('/DoseMeRx_Vancomycin_Enhanced_Obese_Adult_Clinical_Competency.pptx'),
          token,
          <div
            data-testid='vanc-obese'
            className='download-link-div'
            onMouseLeave={() => setVancAdultDivHover(false)}
            onMouseOver={() => setVancAdultDivHover(true)}
          >
            <div className='link-div-wrapper'>
              <div className='doseme-link-div-title mb-2'>Vancomycin Adult Enhanced Obese model kit</div>
              <div className='doseme-link-div-text'>
                Supports dosing calculations for patients with Class I, II, and III obesity.
              </div>
            </div>
            <div data-testid='vanc-obese-icon' className='download-link-div-import-icon'>
              <Icons.Import width={20} height={20} background={vancAdultDivHover ? ADE : undefined} />
            </div>
          </div>
        )}

        {formatExternalLinkDivs(
          downloadDocumentURL('/DoseMeRx_Vancomycin_Pediatric_Clinical_Competency.pptx'),
          token,
          <div
            data-testid='vanc-ped'
            className='download-link-div'
            onMouseLeave={() => setVancPedDivHover(false)}
            onMouseOver={() => setVancPedDivHover(true)}
          >
            <div className='link-div-wrapper'>
              <div className='doseme-link-div-title mb-2'>Vancomycin Pediatric model kit</div>
              <div className='doseme-link-div-text'>
                Clinically validated model for pediatric and adolescent populations.
              </div>
            </div>
            <div data-testid='vanc-ped-icon' className='download-link-div-import-icon'>
              <Icons.Import width={20} height={20} background={vancPedDivHover ? ADE : undefined} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
