import { configure } from 'mobx'

import { AdministrationsStore } from './administrations/AdministrationsStore'
import { ObservationsStore } from './observations/ObservationsStore'
import { AuthStore } from './auth/AuthStore'
import { HospitalStore } from './hospital/HospitalStore'
import { PatientCoursesStore } from './patientCourses/PatientCoursesStore'
import { PatientStore } from './patient/PatientStore'
import { PatientExternalDataStore } from './PatientExternalData/PatientExternalDataStore'
import { CourseStore } from './course/CourseStore'
import { DrugModelsStore } from './drugModels/DrugModelsStore'
import { CourseFeaturesStore } from './courseFeatures/CourseFeaturesStore'
import { DosingRecommendationStore } from './dosingRecommendation/DosingRecommendationStore'
import { ActivityLogStore } from './activityLog/ActivityLogStore'
import { GenerateReportStore } from './generateReport/GenerateReportStore'
import { DrugModelsInformationStore } from './drugModelsInformation/DrugModelsInformationStore'
import { DosingMatrixStore } from './dosingMatrix/DosingMatrixStore'
import { VersionsStore } from './versions/VersionsStore'
import { ErrorsStore } from './errors/ErrorsStore'
import { ApplicableDrugModelsStore } from './applicableDrugModels/ApplicableDrugModelsStore'
import { AdminHospitalListStore } from './Admin/AdminHospitalList/AdminHospitalListStore'
import { AdminVendorListStore } from './Admin/AdminVendorList/AdminVendorListStore'
import { AdminVendorDetailsStore } from './Admin/AdminVendorDetails/AdminVendorDetailsStore'
import { AdminVendorSiteDetailsStore } from './Admin/AdminVendorSiteDetails/AdminVendorSiteDetailsStore'
import { AdminVendorSiteListStore } from './Admin/AdminVendorSiteList/AdminVendorSiteListStore'
import { PatientListStore } from './PatientList/PatientListStore'
import { AdminHospitalDrugListStore } from './Admin/AdminHospitalDrugList/AdminHospitalDrugListStore'
import { AdminHospitalDetailsStore } from './Admin/AdminHospitalDetails/AdminHospitalDetailsStore'
import { TimezoneStore } from './Constants/Timezone/TimezoneStore'
import { ClinicianStore } from './ClinicianStore/ClinicianStore'
import { AdminClinicianListStore } from './Admin/AdminClinicianList/AdminClinicianListStore'
import { AdminClinicianDetailsStore } from './Admin/AdminClinicianDetails/AdminClinicianDetailsStore'
import { HistoricalSimulationStore } from './historicalSimulation/HistoricalSimulationStore'
import { AdminClinicianSecurityStore } from './Admin/AdminClinicianSecurity/AdminClinicianSecurityStore'
import { AdminHospitalDrugDetailsStore } from './Admin/AdminHospitalDrugDetails/AdminHospitalDrugDetailsStore'
import { PasswordRulesStore } from './passwordRules/PasswordRulesStore'
import { AnalyticsStore } from './analytics/AnalyticsStore'
import { AdminChangeLogListStore } from './Admin/AdminChangeLog/AdminChangeLogListStore'

configure({
  useProxies: 'never'
})

export class RootStore {
  authStore: AuthStore
  passwordRulesStore: PasswordRulesStore
  hospitalStore: HospitalStore
  patientListStore: PatientListStore
  patientStore: PatientStore
  patientExternalDataStore: PatientExternalDataStore
  courseFeaturesStore: CourseFeaturesStore
  patientCoursesStore: PatientCoursesStore
  courseStore: CourseStore
  administrationsStore: AdministrationsStore
  observationsStore: ObservationsStore
  drugModelsStore: DrugModelsStore
  historicalSimulationStore: HistoricalSimulationStore
  dosingRecommendationStore: DosingRecommendationStore
  activityLogStore: ActivityLogStore
  generateReportStore: GenerateReportStore
  drugModelsInformationStore: DrugModelsInformationStore
  dosingMatrixStore: DosingMatrixStore
  versionsStore: VersionsStore
  errorsStore: ErrorsStore
  adminHospitalListStore: AdminHospitalListStore
  adminVendorListStore: AdminVendorListStore
  adminVendorDetailsStore: AdminVendorDetailsStore
  adminVendorSiteDetailsStore: AdminVendorSiteDetailsStore
  adminVendorSiteListStore: AdminVendorSiteListStore
  adminHospitalDrugListStore: AdminHospitalDrugListStore
  constantsTimezoneStore: TimezoneStore
  applicableDrugModelsStore: ApplicableDrugModelsStore
  adminHospitalDetailsStore: AdminHospitalDetailsStore
  clinicianStore: ClinicianStore
  adminClinicianListStore: AdminClinicianListStore
  adminClinicianDetailsStore: AdminClinicianDetailsStore
  adminChangeLogListStore: AdminChangeLogListStore
  adminClinicianSecurityStore: AdminClinicianSecurityStore
  adminHospitalDrugDetailsStore: AdminHospitalDrugDetailsStore
  analyticsStore: AnalyticsStore

  constructor() {
    this.authStore = new AuthStore(this)
    this.passwordRulesStore = new PasswordRulesStore(this)
    this.hospitalStore = new HospitalStore(this)
    this.patientListStore = new PatientListStore(this)
    this.patientStore = new PatientStore(this)
    this.patientExternalDataStore = new PatientExternalDataStore(this)
    this.courseFeaturesStore = new CourseFeaturesStore(this)
    this.patientCoursesStore = new PatientCoursesStore(this)
    this.courseStore = new CourseStore(this)
    this.administrationsStore = new AdministrationsStore(this)
    this.observationsStore = new ObservationsStore(this)
    this.drugModelsStore = new DrugModelsStore(this)
    this.historicalSimulationStore = new HistoricalSimulationStore(this)
    this.dosingRecommendationStore = new DosingRecommendationStore(this)
    this.hospitalStore = new HospitalStore(this)
    this.activityLogStore = new ActivityLogStore(this)
    this.generateReportStore = new GenerateReportStore(this)
    this.drugModelsInformationStore = new DrugModelsInformationStore(this)
    this.dosingMatrixStore = new DosingMatrixStore(this)
    this.versionsStore = new VersionsStore(this)
    this.errorsStore = new ErrorsStore(this)
    this.adminHospitalListStore = new AdminHospitalListStore(this)
    this.adminVendorListStore = new AdminVendorListStore(this)
    this.adminVendorDetailsStore = new AdminVendorDetailsStore(this)
    this.adminVendorSiteDetailsStore = new AdminVendorSiteDetailsStore(this)
    this.adminVendorSiteListStore = new AdminVendorSiteListStore(this)
    this.adminHospitalDrugListStore = new AdminHospitalDrugListStore(this)
    this.constantsTimezoneStore = new TimezoneStore(this)
    this.applicableDrugModelsStore = new ApplicableDrugModelsStore(this)
    this.adminHospitalDetailsStore = new AdminHospitalDetailsStore(this)
    this.clinicianStore = new ClinicianStore(this)
    this.adminClinicianListStore = new AdminClinicianListStore(this)
    this.adminClinicianDetailsStore = new AdminClinicianDetailsStore(this)
    this.adminClinicianSecurityStore = new AdminClinicianSecurityStore(this)
    this.adminHospitalDrugDetailsStore = new AdminHospitalDrugDetailsStore(this)
    this.adminChangeLogListStore = new AdminChangeLogListStore(this)
    this.analyticsStore = new AnalyticsStore(this)
  }
}
