import React, { useState, useCallback } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { v4 as uuidv4 } from 'uuid'
import { MessageOverlay } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'

import { appInsights } from '../AzureAppInsights'

import './index.scss'

class DebugError extends Error {}

const ignoreErrors = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
  'ErrorEvent: ResizeObserver loop limit exceeded',
  'ErrorEvent: ResizeObserver loop completed with undelivered notifications'
]

const ErrorFallback: React.FC<FallbackProps & { errorId: string }> = ({ error, resetErrorBoundary, errorId }) => {
  if (errorId) {
    return (
      <div className='error-page-overlay-wrapper'>
        <div className='error-page-overlay-div mt-5'>
          <MessageOverlay
            type='error'
            headerText="Oh no... we've run into a problem"
            messageText={
              <div>
                <div className='mt-2 mb-2'>DoseMeRx encountered an error during launch. </div>
                <div className='mb-2'>
                  Please close the app and try again, or contact our support team for further help – we're available
                  24/7.
                </div>
                <div className='font-weight-bold'>Error ID: {errorId}</div>
              </div>
            }
          />
        </div>
      </div>
    )
  }

  return null
}

const AppInsightsErrorBoundary: React.FC<React.PropsWithChildren> = observer(({ children }) => {
  const [errorId, setErrorId] = useState<string>('')

  const handleError = useCallback((error: Error, info: { componentStack: string }) => {
    if (error?.message && !ignoreErrors.includes(error?.message)) {
      const newErrorId = uuidv4()
      setErrorId(newErrorId)

      appInsights.trackException({
        error: error ? error : undefined,
        exception: error ? error : undefined,
        severityLevel: error instanceof DebugError ? SeverityLevel.Information : SeverityLevel.Error,
        properties: {
          errorId: newErrorId,
          errorMessage: error?.message,
          // Enable the below line when we can use ES6 Error types
          // errorCause: error?.cause,
          info: info,
          installation: window.env.INSTALLATION
        }
      })
    }
  }, [])

  return (
    <ErrorBoundary
      FallbackComponent={(props) => <ErrorFallback {...props} errorId={errorId} />}
      onError={handleError}
    >
      {children}
    </ErrorBoundary>
  )
})

export { AppInsightsErrorBoundary, DebugError }
