import classnames from 'classnames'
import { Dispatch, SetStateAction } from 'react'
import { Button, Icons } from '@doseme/cohesive-ui'

import { buildFormFields, buildTextInput } from '../../../../../../shared/buildForms'
import { IAdminHospitalDrugLimits, IDrugModelLimitSettings } from '../../../../../../store/Admin/AdminHospitalDrugDetails/types'
import { IFormField, IFormState } from '../../../../../../types/validation'
import { TDrugLimitSettingsMap, TInlineEditPanels } from './types'

import './index.scss'

const displayPanelHelpText = (
  label: string,
  selectedHelpText: string[],
  setSelectedHelpText: React.Dispatch<React.SetStateAction<string[]>>
) => {
  selectedHelpText.includes(label)
    ? setSelectedHelpText(selectedHelpText.filter((helpText) => helpText !== label))
    : setSelectedHelpText(selectedHelpText.concat(label))
}

const displayIcon = (
  data: TDrugLimitSettingsMap,
  key: string,
  selectedPanelsHelpTextList: string[],
  setSelectedHelpText: React.Dispatch<React.SetStateAction<string[]>>
): JSX.Element => {
  if (data[key].label === 'Absolute dose limits:') {
    return (
      <div className='abs-dose-limits-icon-outer'>
        <div className='abs-limits-icon'>
          <Icons.Padlock background='grey' height={12} width={12} />
        </div>
        Provided for reference only; not customizable.
      </div>
    )
  }

  if (!selectedPanelsHelpTextList.includes(data[key].label)) {
    return (
      <div
        className='no-modal-panel-icon'
        onClick={() => displayPanelHelpText(data[key].label, selectedPanelsHelpTextList, setSelectedHelpText)}
      >
        <Icons.QuestionMark />
      </div>
    )
  }

  return (
    <div
      className='no-modal-panel-icon-outer'
      onClick={() => displayPanelHelpText(data[key].label, selectedPanelsHelpTextList, setSelectedHelpText)}
    >
      <Icons.QuestionMark /> <div className='icon-text'>Hide</div>
    </div>
  )
}

export const displayNoModalPanels = (
  data: TDrugLimitSettingsMap,
  dataKeys: string[],
  selectedPanelsHelpTextList: string[],
  width: number,
  setSelectedHelpText: React.Dispatch<React.SetStateAction<string[]>>
): JSX.Element[] => {
  let returnElements: JSX.Element[] = []
  dataKeys.forEach((key, index) => {
    if (width < 1080) {
      returnElements.push(
        <div key={key} className='no-modal-panel-data-row'>
          <div className='no-modal-panel-title-row'>
            <div
              className={classnames('hospital-drug-details-panel-label', {
                'abs-dose-limits-color': data[key].label === 'Absolute dose limits:'
              })}
            >
              {data[key].label}
            </div>
            {displayIcon(data, key, selectedPanelsHelpTextList, setSelectedHelpText)}
            <div
              className={classnames('no-modal-panel-data-row-column-1', {
                'abs-dose-limits-color': data[key].label === 'Absolute dose limits:',
                narrow: width < 1080
              })}
            >
              {data[key].limit.default?.value !== undefined
                ? `Default ${data[key].limit.default?.value} ${data[key].limit.default?.unit?.name}`
                : '–'}
            </div>
            <div
              className={classnames('no-modal-panel-data-row-column-2', {
                'abs-dose-limits-color': data[key].label === 'Absolute dose limits:',
                narrow: width < 1080
              })}
            >
              {data[key].limit.min?.value !== undefined
                ? `Min. ${data[key].limit.min?.value} ${data[key].limit.min?.unit?.name}`
                : '–'}
            </div>
            <div
              className={classnames('no-modal-panel-data-row-column-3', {
                'abs-dose-limits-color': data[key].label === 'Absolute dose limits:',
                narrow: width < 1080
              })}
            >
              {data[key].limit.max?.value !== undefined
                ? `Max. ${data[key].limit.max?.value} ${data[key].limit.max?.unit?.name}`
                : '–'}
            </div>
          </div>
          {selectedPanelsHelpTextList.includes(data[key].label) && (
            <div className='no-modal-panel-data-row-help-text'>{data[key].helpText}</div>
          )}
        </div>
      )
    } else {
      returnElements.push(
        <div key={key} className='no-modal-panel-data-row'>
          <div className='no-modal-panel-title-row'>
            <div
              className={classnames('hostpial-drug-details-panel-label', {
                'abs-dose-limits-color': data[key].label === 'Absolute dose limits:'
              })}
            >
              {data[key].label}
            </div>
            {displayIcon(data, key, selectedPanelsHelpTextList, setSelectedHelpText)}
            <div
              className={classnames('no-modal-panel-data-row-column-1', {
                'abs-dose-limits-color': data[key].label === 'Absolute dose limits:'
              })}
            >
              {data[key].limit.default?.value !== undefined
                ? `Default ${data[key].limit.default?.value} ${data[key].limit.default?.unit?.name}`
                : '–'}
            </div>
            <div
              className={classnames('no-modal-panel-data-row-column-2', {
                'abs-dose-limits-color': data[key].label === 'Absolute dose limits:'
              })}
            >
              {data[key].limit.min?.value !== undefined
                ? `Min. ${data[key].limit.min?.value} ${data[key].limit.min?.unit?.name}`
                : '–'}
            </div>
            <div
              className={classnames('no-modal-panel-data-row-column-3', {
                'abs-dose-limits-color': data[key].label === 'Absolute dose limits:'
              })}
            >
              {data[key].limit.max?.value !== undefined
                ? `Max. ${data[key].limit.max?.value} ${data[key].limit.max?.unit?.name}`
                : '–'}
            </div>
          </div>
          {selectedPanelsHelpTextList.includes(data[key].label) && (
            <div className='no-modal-panel-data-row-help-text'>{data[key].helpText}</div>
          )}
        </div>
      )
    }

    if (index !== dataKeys.length - 1) {
      returnElements.push(<hr key={`${key}-hr`}></hr>)
    }
  })

  return [
    <div key='drug-settings-outer' className='display-formatted-details'>
      {returnElements}
    </div>
  ]
}

export const repackageResponseForBuilding = (attributes: any) => {
  return { attributes }
}

export const buildInlineFormsFields = (inlineFormsFormat: any) => {
  const keys = Object.keys(inlineFormsFormat)

  return keys.reduce<Record<string, Record<string, IFormField>>>((acc, key) => {

    return {
      ...acc,
      [key]: buildFormFields(repackageResponseForBuilding(inlineFormsFormat[key]))
    }
  }, {})
}

export const displayInlineEditPanels = (
  width: number,
  data: TDrugLimitSettingsMap,
  formData: Record<string, IAdminHospitalDrugLimits>,
  dataKeys: string[],
  inlineForms: Record<string, IFormState>,
  inlineFormFields: Record<string, Record<string, IFormField>>,
  loadState: any,
  handleSubmit: () => void,
  setisEditable: Dispatch<SetStateAction<TInlineEditPanels>>,
  formKeys?: string[]
) => {
  let returnElements: JSX.Element[] = []
  dataKeys.forEach((key) => {
    returnElements.push(
      <div className='editable-no-modal-panel-data-row'>
        <div className='d-flex'>
          <div className='title'>{`${data[key].label}`}</div>
          {data[key].unit?.name &&
            <div className='unit-outer'>{data[key].unit?.name || null}</div>
          }
          {data[key].label === 'Absolute dose limits:' && (
            <div className='abs-dose-limits-icon-outer'>
              <div className='abs-limits-icon'>
                <Icons.Padlock background='grey' height={12} width={12} />
              </div>
              Provided for reference only; not customizable.
            </div>
          )}
        </div>
        <div className={classnames({ 'd-flex': width >= 1080 })}>
          <div className='description-text'>{data[key].helpText}</div>
          {formContent(width, data[key], formData[key], inlineForms[key], inlineFormFields[key])}
        </div>
      </div>
    )
  })

  const validationKeys = formKeys || dataKeys
  const isFormValid = validationKeys.reduce((acc, key) => {
    return acc && inlineForms[key].valid
  }, true)

  return [
    <div key='drug-settings-outer-editable' className='display-formatted-details'>
      {returnElements}
      <div className='editable-no-modal-panel-btns'>
        <Button
          data-testid='save-btn'
          className='mr-2'
          disabled={!isFormValid}
          loading={['loading', 'updating'].includes(loadState)}
          onClick={handleSubmit}
          variant='primary'
        >
          Update settings
        </Button>
        <Button
          className='mr-3'
          data-testid='cancel-btn'
          loading={['loading', 'updating'].includes(loadState)}
          onClick={() => setisEditable(null)}
          variant='primary-outline'
        >
          Cancel / Reset to saved
        </Button>
      </div>
    </div>
  ]
}

export const formContent = (
  width: number,
  data: IDrugModelLimitSettings,
  formData?: IAdminHospitalDrugLimits,
  form?: IFormState,
  formFields?: Record<string, IFormField>
): JSX.Element => {

  if (width < 1080) {
    return (
      <div className='hospital-drugs-target-settings-inputs narrow'>
        <div className={classnames('input-container', { hidden: !data.limit.default })}>
          <div className='input-title narrow-one'>
            <b>Default</b>
          </div>
          <div className='input'>
            {formData?.default && form && formFields ? (
              buildTextInput(formData.default, form, formFields['default'], 'default', true)
            ) : (
              <div className='input-readonly-value'>{data.limit.default?.value}</div>
            )}
          </div>
        </div>
        <div className={classnames('input-container', { hidden: !data.limit.min })}>
          <div className='input-title'>
            <b>Min.</b>
          </div>
          <div className='input'>
            {formData?.min && form && formFields ? (
              buildTextInput(formData.min, form, formFields['min'], 'min', true)
            ) : (
              <div className='input-readonly-value'>{data.limit.min?.value}</div>
            )}
          </div>
        </div>
        <div className={classnames('input-container', { hidden: !data.limit.max })}>
          <div className='input-title'>
            <b>Max.</b>
          </div>
          <div className='input'>
            {formData?.max && form && formFields ? (
              buildTextInput(formData!.max, form!, formFields!['max'], 'max', true)
            ) : (
              <div className='input-readonly-value'>{data.limit.max?.value}</div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='hospital-drugs-target-settings-inputs'>
      <div className={classnames('input-container', { 'hidden': !data.limit.default })} >
        <div className='input-title one'>
          <b>Default</b>
        </div>
        <div className='input'>
          {formData?.default && form && formFields
            ? buildTextInput(formData.default, form, formFields['default'], 'default', true)
            : <div className='input-readonly-value'>{data.limit.default?.value}</div>
          }
        </div>
      </div>
      <div className={classnames('input-container', { 'hidden': !data.limit.min })} >
        <div className='input-title'>
          <b>Min.</b>
        </div>
        <div className='input'>
          {formData?.min && form && formFields
            ? buildTextInput(formData.min, form, formFields['min'], 'min', true)
            : <div className='input-readonly-value'>{data.limit.min?.value}</div>
          }
        </div>
      </div>
      <div className={classnames('input-container', { 'hidden': !data.limit.max })} >
        <div className='input-title'>
          <b>Max.</b>
        </div>
        <div className='input'>
          {formData?.max && form && formFields
            ? buildTextInput(formData!.max, form!, formFields!['max'], 'max', true)
            : <div className='input-readonly-value'>{data.limit.max?.value}</div>
          }
        </div>
      </div>
    </div>
  )
}

export const arrangeKeysInOrder = (providedKeys: string[], order: string[]) => {
  return order.reduce((acc: string[], key) => {
    if (providedKeys.includes(key)) {
      acc.push(key)
    }

    return acc
  }, [])
}

export const buildPutRequest = (inlineForms: any, storeData: any) => {
  return Object.keys(inlineForms).reduce((acc: Record<string, any>, key) => {

    if (storeData[key]) {
      acc[key] = {
        default: storeData[key].default
          ? {
            value: inlineForms[key].values.default,
            unit: storeData[key].default.attributes.units
          }
          : undefined,
        min: storeData[key].min
          ? {
            value: inlineForms[key].values.min,
            unit: storeData[key].min.attributes.units
          }
          : undefined,
        max: storeData[key].max
          ? {
            value: inlineForms[key].values.max,
            unit: storeData[key].max.attributes.units
          }
          : undefined
      }
    }

    return acc
  }, {} as Record<string, any>)
}

export const setDefaultValueAsNull = (value?: string | null) => {
  return !value || value === 'default'
    ? null
    : value.toString()
}
