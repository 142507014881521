import { ADE, BackArrowButton, Icons } from '@doseme/cohesive-ui'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { formatExternalLinkDivs, downloadDocumentURL } from '../../utils'
import { handleBackButton } from '../../../../utils/navigation'

import '../index.scss'

export const Compliance: React.FC = () => {
  const [englishIFUHover, setEnglishIFUHover] = useState<boolean>(false)
  const [spanishIFUHover, setSpanishIFUHover] = useState<boolean>(false)
  const [portugueseIFUHover, setPortugueseIFUHover] = useState<boolean>(false)

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div data-testid='compliance' className='co-resource-page'>
      <div className='d-flex'>
        <div className='resource-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() => handleBackButton('../.', navigate, location)}
          />
        </div>
        <div className='resource-title compliance'>Compliance</div>
      </div>

      <div className='resource-panel'>
        <div className='resource-panel-title'>
          Download our Instructions for Use version 2.0.
        </div>

        {formatExternalLinkDivs(
          downloadDocumentURL('/DoseMeRx_IFU_English.pdf'),
          undefined,
          <div
            data-testid='compliance-english-ifu'
            className='download-link-div'
            onMouseLeave={() => setEnglishIFUHover(false)}
            onMouseOver={() => setEnglishIFUHover(true)}
          >
            <div className='link-div-wrapper'>
              <div className='doseme-link-div-title'>DoseMeRx IFU 2.0 (English)</div>
            </div>
            <div data-testid='compliance-english-ifu-icon' className='download-link-div-import-icon'>
              <Icons.Import width={20} height={20} background={englishIFUHover ? ADE : undefined} />
            </div>
          </div>
        )}

        {formatExternalLinkDivs(
          downloadDocumentURL('/DoseMeRx_IFU_Spanish.pdf'),
          undefined,
          <div
            data-testid='compliance-spanish-ifu'
            className='download-link-div'
            onMouseLeave={() => setSpanishIFUHover(false)}
            onMouseOver={() => setSpanishIFUHover(true)}
          >
            <div className='link-div-wrapper'>
              <div className='doseme-link-div-title'>DoseMeRx IFU 2.0 (Spanish)</div>
            </div>
            <div data-testid='compliance-spanish-ifu-icon' className='download-link-div-import-icon'>
              <Icons.Import width={20} height={20} background={spanishIFUHover ? ADE : undefined} />
            </div>
          </div>
        )}

        {formatExternalLinkDivs(
          downloadDocumentURL('/DoseMeRx_IFU_Portuguese.pdf'),
          undefined,
          <div
            data-testid='compliance-portuguese-ifu'
            className='download-link-div'
            onMouseLeave={() => setPortugueseIFUHover(false)}
            onMouseOver={() => setPortugueseIFUHover(true)}
          >
            <div className='link-div-wrapper'>
              <div className='doseme-link-div-title'>DoseMeRx IFU 2.0 (Portuguese)</div>
            </div>
            <div data-testid='compliance-portuguese-ifu-icon' className='download-link-div-import-icon'>
              <Icons.Import width={20} height={20} background={portugueseIFUHover ? ADE : undefined} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
