import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Button,
  FloatingTabBar,
  Icons,
  InfoBubble,
  LOADING_GREY,
  TextInput
} from '@doseme/cohesive-ui'

import { useAuthStore, useClinicianStore, usePasswordRulesStore } from '../../hooks/useStore'
import { buildFormFields, buildSelectionInput, buildTextInput } from '../../shared/buildForms'
import { useFormValidation } from '../../hooks/useFormValidation'
import { adminClinicianDetailsTabs, passwordFormFields } from './constants'
import { TClinicianSettingsTab } from './types'
import { IPasswordRulesCheck } from '../../shared/checkPassword/types'
import { checkPasswordRules, checkPasswordValid } from '../../shared/checkPassword'
import { IPutEditClinicianDetails, IPutEditClinicianPassword } from '../../store/ClinicianStore/types'
import { showErrorToast, showSuccessToast } from '../../shared/toast'

import './index.scss'

export const ClinicianSettings: React.FC = observer(() => {
  const [clinicianSettingsTab, setClinicianSettingsTab] = useState<TClinicianSettingsTab>('profile')
  const [passwordCheck, setPasswordCheck] = useState<IPasswordRulesCheck>({
    upperCaseLetter: false,
    lowerCaseLetter: false,
    number: false,
    characterLength: false,
    passwordsMatch: false
  })
  const [isShowExistingPasswordChecked, setIsShowExistingPasswordChecked] = useState<boolean>(false)
  const [isShowNewPasswordChecked, setIsShowNewPasswordChecked] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const authStore = useAuthStore()
  const passwordRulesStore = usePasswordRulesStore()
  const clinicianStore = useClinicianStore()

  useEffect(() => {
    if (authStore.loadState === 'loaded' && authStore.auth) {
      clinicianStore.fetchEditClinicianDetail(authStore.auth.attributes.clinicianId)
    }

    if (passwordRulesStore.loadState === 'initial') {
      passwordRulesStore.getPasswordRules()
    }
  }, [])

  const profileFormFields = useMemo(() => {
    if (clinicianStore.editClinicianLoadState === 'loaded' && clinicianStore.editClinicianDetails) {
      return buildFormFields(clinicianStore.editClinicianDetails)
    }

    return {}
  }, [clinicianStore.editClinicianLoadState])

  const profileForm = useFormValidation(profileFormFields)
  const passwordForm = useFormValidation(passwordFormFields)

  useEffect(() => {
    if (clinicianStore.editClinicianLoadState === 'loaded' && clinicianStore.editClinicianDetails) {
      profileForm.reset()
    }
  }, [clinicianStore.editClinicianLoadState])

  const handleNewPasswordChange = (newPassword: string) => {
    if (passwordRulesStore.passwordRules?.attributes) {
      const newPasswordCheck = checkPasswordRules(
        newPassword,
        newPassword,
        passwordRulesStore.passwordRules.attributes
      )

      setPasswordCheck(newPasswordCheck)
    }
  }

  const handleSubmit = async () => {
    if (clinicianStore.editClinicianDetails?.id) {
      await clinicianStore.putEditClinicianDetail(clinicianStore.editClinicianDetails?.id, profileForm.values as IPutEditClinicianDetails)

      if (['loadError', 'updateError'].includes(clinicianStore.editClinicianLoadState)) {
        showErrorToast(clinicianStore.error || 'Failed to update Profile Settings')

        return
      }

      showSuccessToast('Profile Settings updated')
    }
  }

  const handleUpdatePassword = async () => {
    if (clinicianStore.editClinicianDetails?.id) {
      await clinicianStore.putEditClinicianPassword(clinicianStore.editClinicianDetails?.id, passwordForm.values as IPutEditClinicianPassword)

      if (['loadError', 'updateError'].includes(clinicianStore.updatePasswordLoadState)) {
        showErrorToast(clinicianStore.updatePasswordError || 'Failed to update Password')
        setErrorMessage(clinicianStore.updatePasswordError || 'Failed to update Clinician Password')

        return
      }

      showSuccessToast('Password updated')
      setErrorMessage(undefined)
      passwordForm.reset()
    }
  }

  const profileFormContent = (): JSX.Element => {
    if (clinicianStore.editClinicianLoadState === 'loaded' && clinicianStore.editClinicianDetails) {
      return (
        <div className='clinician-settings-profile-form'>
          <div className='clinician-settings-email-title'>Email</div>
          <div className='clinician-settings-email d-flex'>
            {clinicianStore.clinician?.attributes.email}
          </div>
          {buildSelectionInput(clinicianStore.editClinicianDetails.attributes['title'], profileForm, 'title')}
          <div className='d-flex'>
            <div className='mr-3'>
              {buildTextInput(
                clinicianStore.editClinicianDetails.attributes['firstName'],
                profileForm,
                profileFormFields['firstName'],
                'firstName'
              )}
            </div>
            {buildTextInput(
              clinicianStore.editClinicianDetails.attributes['lastName'],
              profileForm,
              profileFormFields['lastName'],
              'lastName'
            )}
          </div>
        </div>
      )
    }

    return (
      <div className='clinician-settings-profile-spinner'>
        <Icons.ThinSpinner strokeWidth={12} r={32} stroke={LOADING_GREY} width='64px' />
      </div>
    )
  }

  const passwordValidityContent = () => {
    if (passwordRulesStore.loadState === 'loaded' && passwordRulesStore.passwordRules) {
      const passwordRules = passwordRulesStore.passwordRules.attributes

      return (
        <div className='clinician-settings-password-validation'>
          <div className='clinician-settings-password-validation-title'>Passwords must contain:</div>
          {passwordRules.requiresUpperCase &&
              <div className='clinician-settings-password-validation-row'>
                {passwordCheck.upperCaseLetter
                  ? <Icons.SuccessCircle />
                  : <Icons.UnavailableCircle />
                }
                <div className='clinician-settings-password-validation-text'>One upper-case letter</div>
              </div>
          }
          {passwordRules.requiresLowerCase &&
              <div className='clinician-settings-password-validation-row'>
                {passwordCheck.lowerCaseLetter
                  ? <Icons.SuccessCircle />
                  : <Icons.UnavailableCircle />
                }
                <div className='clinician-settings-password-validation-text'>One lower-case letter</div>
              </div>
          }
          {passwordRules.requiresNumOrSymbol &&
              <div className='clinician-settings-password-validation-row'>
                {passwordCheck.number
                  ? <Icons.SuccessCircle />
                  : <Icons.UnavailableCircle />
                }
                <div className='clinician-settings-password-validation-text'>One number or symbol</div>
              </div>
          }
          <div className='clinician-settings-password-validation-row'>
            {passwordCheck.characterLength
              ? <Icons.SuccessCircle />
              : <Icons.UnavailableCircle />
            }
            <div className='clinician-settings-password-validation-text'>
              {`Between ${passwordRules.minLength} and ${passwordRules.maxLength} characters`}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className='clinician-settings-profile-spinner'>
        <Icons.ThinSpinner strokeWidth={12} r={32} stroke={LOADING_GREY} width='64px' />
      </div>
    )
  }

  const passwordFormContent = (): JSX.Element => {
    return (
      <div className='clinician-settings-profile-form'>
        <div className='clinician-settings-password-label'>
          <div className='clinician-settings-password-input-label'>Existing password:</div>
          <div className='clinician-settings-password-show-password' onClick={() => setIsShowExistingPasswordChecked(!isShowExistingPasswordChecked)}>
            <input
              type='checkbox'
              checked={isShowExistingPasswordChecked}
              readOnly
            />
            <div className='clinician-settings-password-show-password-label'>Show password</div>
          </div>
        </div>
        <TextInput
          type={isShowExistingPasswordChecked ? 'text' : 'password'}
          fieldState={passwordForm.getValidState('currentPassword')}
          value={passwordForm.inputs['currentPassword']}
          name={'currentPassword'}
          onChange={(value) => {
            passwordForm.validateFields([
              {
                field: 'currentPassword',
                input: value
              }
            ])
          }
          }
          onBlur={() => passwordForm.updateFieldsDisplay(['currentPassword'])}
        />
        <div className='clinician-settings-password-label'>
          <div className='clinician-settings-password-input-label'>New password:</div>
          <div className='clinician-settings-password-show-password' onClick={() => setIsShowNewPasswordChecked(!isShowNewPasswordChecked)}>
            <input
              type='checkbox'
              checked={isShowNewPasswordChecked}
              readOnly
            />
            <div className='clinician-settings-password-show-password-label'>Show password</div>
          </div>
        </div>
        <TextInput
          type={isShowNewPasswordChecked ? 'text' : 'password'}
          fieldState={passwordForm.getValidState('newPassword')}
          value={passwordForm.inputs['newPassword']}
          name={'newPassword'}
          onChange={(value) => {
            passwordForm.validateFields([
              {
                field: 'newPassword',
                input: value
              }
            ])
            handleNewPasswordChange(value)
          }
          }
          onBlur={() => passwordForm.updateFieldsDisplay(['newPassword'])}
        />
        {
          errorMessage && (
            <div className='reset-password-error'>
              <InfoBubble type='error' bubbleTitle={errorMessage} borderRadius={6} />
            </div>
          )
        }
        {passwordValidityContent()}
      </div>
    )
  }

  const displayClinicianDetailsPanelContent = (): JSX.Element => {
    if (clinicianSettingsTab === 'password' && passwordRulesStore.passwordRules) {
      const validPassword = checkPasswordValid(
        passwordCheck,
        passwordRulesStore.passwordRules.attributes
      )

      return (
        <div className='clinician-settings'>
          {passwordFormContent()}
          <Button
            onClick={handleUpdatePassword}
            variant='primary'
            disabled={!validPassword}
            loading={['updating', 'loading'].includes(clinicianStore.updatePasswordLoadState)}>
            Update Password
          </Button>
        </div>
      )
    }

    return (
      <div className='clinician-settings'>
        {profileFormContent()}
        <Button
          onClick={() => handleSubmit()}
          variant='primary'
          disabled={!profileForm.valid || clinicianStore.editClinicianLoadState !== 'loaded'}
          loading={['loading', 'updating'].includes(clinicianStore.editClinicianLoadState)}>
          Update Profile
        </Button>
        <Button
          onClick={() => profileForm.reset()}
          variant='secondary-hover'
          disabled={clinicianStore.editClinicianLoadState !== 'loaded'}
          loading={['loading', 'updating'].includes(clinicianStore.editClinicianLoadState)}>
          Reset to saved
        </Button>
      </div>
    )
  }

  return (
    <div className='clinician-settings-page'>
      <div className='clinician-settings-header'>
        <div className='clinician-settings-title'>
          Your Profile
        </div>
      </div>
      <div className='clinician-settings-content-panel'>
        <div>
          <FloatingTabBar
            tabNames={adminClinicianDetailsTabs}
            activeTab={clinicianSettingsTab}
            onSelectTab={(tab) => setClinicianSettingsTab(tab as TClinicianSettingsTab)}
          />
        </div>
        {displayClinicianDetailsPanelContent()}
      </div>
    </div>
  )
})
