import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BackArrowButton, FloatingTabBar, ListButton, LOADING_GREY, ScrollablePanel } from '@doseme/cohesive-ui'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Icons } from '@doseme/cohesive-ui'

import { useAdminChangeLogListStore, useClinicianStore } from '../../../../hooks/useStore'
import { IChangeLogItem, TChangeLogType } from '../../../../store/Admin/AdminChangeLog/types'
import { handleBackButton } from '../../../../utils/navigation'
import { weekInMilliseconds } from './constants'
import { ChangeLogEntry } from './components/ChangeLogEntry'
import { getChangeLogTabs, sortChangeLogs } from './utils'

import './index.scss'

export const ChangeLog: React.FC = observer(() => {
  const [activeChangelogTab, setActiveChangelogTab] = useState<TChangeLogType>('all')
  const [fetchCount, setFetchCount] = useState<number>(20)
  const [firstLoad, setFirstLoad] = useState<boolean>(true)

  const navigate = useNavigate()
  const location = useLocation()

  const adminChangeLogStore = useAdminChangeLogListStore()
  const clinicianStore = useClinicianStore()

  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    adminChangeLogStore.fetchAdminChangeLogs(fetchCount, activeChangelogTab)
  }, [fetchCount, activeChangelogTab])

  useEffect(() => {
    if (adminChangeLogStore.loadState === 'loaded' && firstLoad) {
      setFirstLoad(false)
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight
      }
    }
  }, [adminChangeLogStore.loadState])

  const renderChangeLogs = () => {
    const filteredChangeLogs = activeChangelogTab === 'all'
      ? [...adminChangeLogStore.adminChangeLogs.values()]
      : [...adminChangeLogStore.adminChangeLogs.values()].filter((changeLog: IChangeLogItem) => {
        return changeLog.attributes.type === activeChangelogTab
      })

    const sortedFilteredChangeLogs = sortChangeLogs(filteredChangeLogs)

    const [lastSevenDays, afterLastSevenDays] = sortedFilteredChangeLogs.reduce<[JSX.Element[], JSX.Element[]]>(
      (acc: [JSX.Element[], JSX.Element[]], changeLog: IChangeLogItem) => {
        if (moment().diff(moment(changeLog.attributes.created)) <= weekInMilliseconds) {
          acc[0].push(
            <ChangeLogEntry key={changeLog.id} changeLog={changeLog} />
          )

          return acc
        }

        acc[1].push(
          <ChangeLogEntry key={changeLog.id} changeLog={changeLog} />
        )

        return acc
      }, [[], []])

    if (lastSevenDays.length === 0) {
      return afterLastSevenDays
    }

    return afterLastSevenDays.concat(renderSeperator(), lastSevenDays)
  }

  const renderSeperator = () => {
    return (
      <div key='change-log-seperator' className='change-log-seperator'>
        <div className='change-log-seperator-line' />
        <div className='change-log-seperator-text'>
          Updated in the last 7 days
        </div>
        <div className='change-log-seperator-line' />
      </div>
    )
  }

  return (
    <div className='changelog-page'>
      <div className='changelog-heading'>
        <div className='changelog-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() => handleBackButton('../.', navigate, location) }
          />
        </div>
        <div className='changelog-title'>Change log</div>
      </div>
      <div className='changelog-panel'>
        <div className='mb-3 '>
          <FloatingTabBar
            tabNames={getChangeLogTabs(clinicianStore.clinician?.attributes.isSuperAdmin)}
            activeTab={activeChangelogTab}
            onSelectTab={(tab) => {
              setFetchCount(20)
              setFirstLoad(true)
              setActiveChangelogTab(tab as TChangeLogType)
            }}
          />
        </div>
        <div className='changelog-container'>
          <ScrollablePanel anchorTop={false} scrollRef={scrollRef}>
            {adminChangeLogStore.loadState === 'loading' ? (
              <div className='change-log-loading-container'>
                <Icons.ThinSpinner strokeWidth={12} r={30} stroke={LOADING_GREY} width='60px' />
              </div>
            ) : (
              <div className='change-log-entries-container'>
                <div className='change-log-end-of-scroll'>
                  {adminChangeLogStore.count < fetchCount ? (
                    <div className='change-log-end-reached-message'>Previous data not available. </div>
                  ) : (
                    <ListButton onClick={() => setFetchCount(fetchCount * 2)}>
                      <FontAwesomeIcon icon={faChevronUp} />
                      Load more
                    </ListButton>
                  )}
                </div>
                {renderChangeLogs()}
              </div>
            )}
          </ScrollablePanel>
        </div>
      </div>
    </div>
  )
})
