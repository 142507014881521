import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ObservableMap } from 'mobx'
import { Tooltip } from 'react-tooltip'
import { decode } from 'he'
import {
  ActionButton,
  BackArrowButton,
  FloatingTabBar,
  IColumnElement,
  IRowElement,
  Icons,
  InfoBubble,
  LOADING_GREY,
  ListButton,
  PaginationPanel,
  SliderButton,
  SmartList,
  TooltipMenu,
  Button
} from '@doseme/cohesive-ui'

import {
  useAdminClinicianDetailsStore,
  useAdminClinicianListStore,
  useAdminClinicianSecurityStore,
  useAuthStore,
  useClinicianStore
} from '../../../../../../hooks/useStore'
import {
  IAdminClinicianDetailsHospitalAccessList,
  IPutAdminClinicianDetails
} from '../../../../../../store/Admin/AdminClinicianDetails/types'
import { getPageCount, paginate } from '../../../../../../utils/pagination'
import { showErrorToast, showSuccessToast } from '../../../../../../shared/toast'
import {
  buildFormFields,
  buildMultipleSelectionInput,
  buildSelectionInput,
  buildTextInput
} from '../../../../../../shared/buildForms'
import { useFormValidation } from '../../../../../../hooks/useFormValidation'
import { ClinicianAddHospitalAdminAccessModal } from './components/ClinicianAddHospitalAdminAccessModal'
import { TAdminClinicianDetailsFilter } from './types'
import {
  adminClinicianDetailsTabs,
  disabledCliniciansActionData,
  hospitalAccessColumns,
  itemsPerPage
} from './constants'
import { ResetPasswordModal } from './components/ResetPasswordModal'
import { handleBackButton } from '../../../../../../utils/navigation'

import './index.scss'

export const ClinicianDetails: React.FC = observer(() => {
  const tab = useLocation().hash && useLocation().hash === '#hospitalaccess' ? 'hospitalaccess' : 'profile'

  const [activeCliniciansTab, setActiveCliniciansTab] = useState<TAdminClinicianDetailsFilter>(
    (tab as 'profile' | 'hospitalaccess' | 'security') || 'profile'
  )
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, updateCurrentPage] = useState(1)
  const [clinicianHospitals, setClinicianHospitals] = useState<IRowElement[]>([])
  const [clinicianAddHospitalAdminAccessModal, setClinicianAddHospitalAdminAccessModal] = useState<boolean>(false)
  const [resetPasswordModal, setResetPasswordModal] = useState<boolean>(false)

  const adminClinicianDetailsStore = useAdminClinicianDetailsStore()
  const adminClinicianSecurityStore = useAdminClinicianSecurityStore()
  const adminClinicianListStore = useAdminClinicianListStore()
  const authStore = useAuthStore()
  const clinicianStore = useClinicianStore()

  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const clinicianId = params['clinicianId']!

  useEffect(() => {
    if (clinicianStore.loadState !== 'loaded' && authStore.auth) {
      clinicianStore.fetchClinician(authStore.auth.attributes.clinicianId)
    }
  }, [])

  useEffect(() => {
    adminClinicianDetailsStore.fetchAdminClinicianDetails(clinicianId)
  }, [clinicianId])

  useEffect(() => {
    if (activeCliniciansTab === 'hospitalaccess') {
      adminClinicianDetailsStore.fetchAdminClinicianHospitalAccess(clinicianId)
    }

    if (activeCliniciansTab === 'security') {
      adminClinicianSecurityStore.fetchAdminClinicianSecurityDetails(clinicianId)
    }
  }, [activeCliniciansTab])

  useEffect(() => {
    if (adminClinicianDetailsStore.settingsLoadStates.hospitalAccess === 'loaded') {
      const formattedData = formatHospitalAccessList(adminClinicianDetailsStore.adminClinicianDetailsHospitalAccess)
      setClinicianHospitals(formattedData)
      setTotalPages(getPageCount(formattedData.length, itemsPerPage))
    }
  }, [adminClinicianDetailsStore.settingsLoadStates.hospitalAccess])

  useEffect(() => {
    if (adminClinicianDetailsStore.loadState === 'loaded') {
      form.reset()
    }
  }, [adminClinicianDetailsStore.loadState])

  const formFields = useMemo(() => {
    if (adminClinicianDetailsStore.loadState === 'loaded' && adminClinicianDetailsStore.adminClinicianDetails) {
      return buildFormFields(adminClinicianDetailsStore.adminClinicianDetails)
    }

    return {}
  }, [adminClinicianDetailsStore.loadState])

  const form = useFormValidation(formFields)

  const handleEnableDisableClinician = async () => {
    if (adminClinicianDetailsStore.adminClinicianDetails) {
      if (adminClinicianDetailsStore.adminClinicianDetails.attributes.enabled.attributes.currentValue) {
        await adminClinicianListStore.disableAdminClinicians([adminClinicianDetailsStore.adminClinicianDetails.id])
      } else {
        await adminClinicianListStore.enableAdminClinicians([adminClinicianDetailsStore.adminClinicianDetails.id])
      }
    }
    adminClinicianDetailsStore.fetchAdminClinicianDetails(clinicianId)
  }

  const handleClinicianRemoveHospitalAccessButton = async (clinicianId: string, hospitalId: string) => {
    await adminClinicianDetailsStore.removeClinicianHospitalAccess(clinicianId, hospitalId)

    if (['loadError', 'updateError'].includes(adminClinicianDetailsStore.settingsLoadStates.hospitalAccess)) {
      showErrorToast(adminClinicianDetailsStore.settingsErrors.hospitalAccess || 'Failed to remove Hospital Access')

      return
    }

    showSuccessToast('Removed Hospital Access')
  }

  const resetClinician2FA = async () => {
    await adminClinicianSecurityStore.resetAdminClinicianSecurityDetails(clinicianId)

    if (['loadError', 'updateError'].includes(adminClinicianSecurityStore.loadState)) {
      showErrorToast(adminClinicianSecurityStore.error || 'Failed to reset clinician 2FA')

      return
    }

    showSuccessToast('Successfuly reset clinician 2FA')

    adminClinicianSecurityStore.fetchAdminClinicianSecurityDetails(clinicianId)
  }

  const handleSubmitClinicianProfile = async () => {
    // enabled key is not required for this PUT
    let formValues = form.values
    delete formValues['enabled']
    await adminClinicianDetailsStore.editAdminClinicianDetails(
      clinicianId,
      formValues as IPutAdminClinicianDetails
    )
    if (['loadError', 'updateError'].includes(adminClinicianDetailsStore.loadState)) {
      showErrorToast(adminClinicianDetailsStore.error || 'Failed to update Clinician Details')

      return
    }

    showSuccessToast('Clinician Details updated')
  }

  const formContent = (): JSX.Element => {
    const isEmailEditable = clinicianStore.clinician?.attributes.isSuperAdmin
      && clinicianStore.clinician.id !== adminClinicianDetailsStore.adminClinicianDetails?.id

    if (
      adminClinicianDetailsStore.loadState === 'loaded' &&
      adminClinicianDetailsStore.adminClinicianDetails
    ) {
      return (
        <div>
          <div className='mt-5'>
            {isEmailEditable
              ? buildTextInput(
                adminClinicianDetailsStore.adminClinicianDetails.attributes['email'],
                form,
                formFields['email'],
                'email'
              )
              : (
                <div>
                  <div className='clinician-settings-email-title'>Email</div>
                  <div className='clinician-settings-email d-flex'>
                    {adminClinicianDetailsStore.adminClinicianDetails.attributes.email.attributes.currentValue}
                  </div>
                </div>
              )
            }
          </div>

          <div className='d-flex'>
            <div className='clinician-settings-inline-inputs mr-3 mt-3'>
              {buildSelectionInput(adminClinicianDetailsStore.adminClinicianDetails.attributes['title'], form, 'title')}
            </div>
            {/* FIXME To be implemented in Future */}
            {/* <div className='clinician-settings-inline-inputs mt-3'>
              {buildSelectionInput(
                adminClinicianDetailsStore.adminClinicianDetails.attributes['suffix'],
                form,
                'suffix'
              )}
            </div> */}
          </div>
          <div className='d-flex'>
            <div className='clinician-settings-inline-inputs mr-3 mt-3'>
              {buildTextInput(
                adminClinicianDetailsStore.adminClinicianDetails.attributes['firstName'],
                form,
                formFields['firstName'],
                'firstName'
              )}
            </div>

            <div className='clinician-settings-inline-inputs mt-3'>
              {buildTextInput(
                adminClinicianDetailsStore.adminClinicianDetails.attributes['lastName'],
                form,
                formFields['lastName'],
                'lastName'
              )}
            </div>
          </div>
          <div className='mt-3'>
            {buildTextInput(
              adminClinicianDetailsStore.adminClinicianDetails.attributes['department'],
              form,
              formFields['department'],
              'department'
            )}
          </div>

          {clinicianStore.clinician?.attributes.isSuperAdmin &&
            <div className='mt-3'>
              {buildMultipleSelectionInput(
                adminClinicianDetailsStore.adminClinicianDetails.attributes['userRoles'],
                form,
                'userRoles'
              )}
            </div>
          }
        </div>
      )
    }

    return (
      <div className='clinician-details-spinner'>
        <Icons.ThinSpinner strokeWidth={12} r={32} stroke={LOADING_GREY} width='64px' />
      </div>
    )
  }

  const infoBubbleTitle: JSX.Element = (
    <div className='font-weight-bold'>
      Hospital uses Single Sign On (SSO) for login.
      <p>Please contact your hospital’s IT support team regarding any login or password settings.</p>
    </div>
  )

  const toggleAdminClinicianHospitalRights = async (hospitalId: string, isAdmin: boolean) => {
    await adminClinicianDetailsStore.toggleClinicianHospitalAdminRights(clinicianId, hospitalId, !isAdmin)
    if (['updateError', 'loadError'].includes(adminClinicianDetailsStore.settingsLoadStates.hospitalAccess)) {
      showErrorToast(adminClinicianDetailsStore.error || 'Failed to update admin rights')

      return
    }

    if (adminClinicianDetailsStore.loadState === 'loaded') {
      showSuccessToast('Admin rights updated')
    }
  }

  const formatHospitalAccessList = (
    hospitals: ObservableMap<string, IAdminClinicianDetailsHospitalAccessList>
  ): IRowElement[] => {
    return [...hospitals].map(([key, hospital]) => {
      const recordColumns: IColumnElement[] = [
        {
          name: 'Hospital name',
          text: decode(hospital.attributes.name || '')
        },
        {
          name: 'Admin at hospital',
          element: (
            <div data-tooltip-id={`clinician-hospital-admin-rights-tooltip-${hospital.id}`}>
              <SliderButton
                isChecked={hospital.attributes.isAdmin}
                onToggle={() => toggleAdminClinicianHospitalRights(hospital.id, hospital.attributes.isAdmin)}
                disabled={!hospital.attributes.isRequestorAdmin}
              />
              {!hospital.attributes.isRequestorAdmin && (
                <div className='model-fit-info'>
                  <Tooltip
                    className='clinician-hospital-access-info-tooltip'
                    id={`clinician-hospital-admin-rights-tooltip-${hospital.id}`}
                    place='top'
                    offset={0}
                  >
                    Unable to make changes, as you do not <br/>
                    have admin access at this hospital
                  </Tooltip>
                </div>
              )}
            </div>
          ),
          text: ''
        },
        {
          name: '',
          element: (
            <div data-testid='ellipsis-icon'>
              <TooltipMenu
                button={
                  <ListButton size='cm'>
                    <Icons.Ellipsis />
                  </ListButton>
                }
                data={disabledCliniciansActionData(
                  clinicianId,
                  hospital.id,
                  handleClinicianRemoveHospitalAccessButton
                )}
                alignRight
                chevronOffset={10}
              />
            </div>
          )
        }
      ]

      return {
        id: hospital.id,
        columns: recordColumns
      }
    })
  }

  const displayClinicianDetailsPanelContent = (): JSX.Element => {
    if (activeCliniciansTab === 'hospitalaccess') {
      return (
        <div>
          <div className='clinician-list-list'>
            <SmartList
              cols={hospitalAccessColumns()}
              data={paginate(clinicianHospitals, { currentPage, itemsPerPage })}
              defaultSortColumn='Name'
              defaultSortDirection='desc'
              loading={adminClinicianDetailsStore.settingsLoadStates.hospitalAccess === 'loading'}
              textIfEmpty='No data to display'
            />
            <div className='clinician-pagination-panel'>
              <PaginationPanel currentPage={currentPage} totalPages={totalPages} onPageChange={updateCurrentPage} />
            </div>
          </div>
          <div className='hospital-access-action-btn'>
            <ActionButton
              actionType='add'
              onClick={() => setClinicianAddHospitalAdminAccessModal(true)}
              customLabel='Add access to another hospital'
            />
          </div>
        </div>
      )
    }

    if (activeCliniciansTab === 'security') {
      return (
        <div className='edit-clinician-security-div'>
          {adminClinicianSecurityStore.adminClinicianSecurity?.attributes.currentHospital.loginMethod === 'sso' && (
            <InfoBubble type='info' bubbleTitle={infoBubbleTitle} borderRadius={6} />
          )}
          <div className='security-content-div'>
            <div className='title-text'>2FA setup:</div>
            <div className='d-flex mt-2'>
              {!adminClinicianSecurityStore.adminClinicianSecurity?.attributes.twoFASetup ? (
                <div className='clinician-2fa-div'>
                  User has not set up 2FA
                  <div className='clinician-2fa-symbol'>
                    <Icons.CancelCircle />
                  </div>
                </div>
              ) : (
                <div className='clinician-2fa-div'>
                  Yes
                  <div className='clinician-2fa-symbol'>
                    <Icons.Success />
                  </div>
                </div>
              )}
              <div className='clinician-2fa-reset-btn'>
                <ActionButton
                  actionType='noIcon'
                  onClick={() => resetClinician2FA()}
                  customLabel='Reset 2FA'
                  disabled={!adminClinicianSecurityStore.adminClinicianSecurity?.attributes.twoFASetup}
                />
              </div>
            </div>
            {clinicianStore.clinician?.attributes.isSuperAdmin &&
              <>
                <div className='title-text'>Super-admin password reset:</div>
                <div className='d-flex mt-2'>
                  <ActionButton
                    actionType='edit'
                    onClick={() => setResetPasswordModal(true)}
                    customLabel='Manually reset password'
                  />
                </div>
              </>
            }
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className='edit-clinician-profile-form-content'>
          {formContent()}
          <div className='edit-clinician-profile-form-buttons' >
            <Button
              onClick={handleSubmitClinicianProfile}
              variant='primary'
              disabled={!form.valid || !['loaded', 'loadError'].includes(adminClinicianDetailsStore.loadState)}
              loading={['loading', 'updating'].includes(adminClinicianDetailsStore.loadState)}>
              Update user profile
            </Button>
            <Button
              onClick={() => form.reset()}
              variant='secondary-hover'
              disabled={!['loaded', 'loadError'].includes(adminClinicianDetailsStore.loadState)}
              loading={['loading', 'updating'].includes(adminClinicianDetailsStore.loadState)}>
              Reset to saved
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='co-clinician-show-page'>
      <ClinicianAddHospitalAdminAccessModal
        show={clinicianAddHospitalAdminAccessModal}
        setShow={setClinicianAddHospitalAdminAccessModal}
        clinicianId={clinicianId}
        clinicianName={
          `${decode(adminClinicianDetailsStore.adminClinicianDetails?.attributes.firstName.attributes.currentValue || '')}
            ${decode(adminClinicianDetailsStore.adminClinicianDetails?.attributes.lastName.attributes.currentValue || '')}` || null
        }
      />
      <ResetPasswordModal
        show={resetPasswordModal}
        setShow={setResetPasswordModal}
        clinicianId={clinicianId}
        clinicianName={
          `${decode(adminClinicianDetailsStore.adminClinicianDetails?.attributes.firstName.attributes.currentValue || '')}
            ${decode(adminClinicianDetailsStore.adminClinicianDetails?.attributes.lastName.attributes.currentValue || '')}`
        }
      />
      <div className='clinician-details-heading'>
        <div className='clinician-details-back-btn'>
          <BackArrowButton data-testid='back-btn' onClick={() => handleBackButton('../.', navigate, location)} />
        </div>
        <div className='clinician-details-title'>
          Clinician settings:
          <b>
            {` ${decode(adminClinicianDetailsStore.adminClinicianDetails?.attributes.firstName.attributes.currentValue || '')}
              ${decode(adminClinicianDetailsStore.adminClinicianDetails?.attributes.lastName.attributes.currentValue || '')}`}
          </b>
        </div>
      </div>
      <div className='enable-clinician-panel'>
        <div className='enable-clinician-slider-div'>
          Enable clinician:
          <div className='enable-clinician-slider'>
            <SliderButton
              disabled={adminClinicianDetailsStore.loadState !== 'loaded'
                || clinicianStore.clinician?.id === adminClinicianDetailsStore.adminClinicianDetails?.id
                || ['loading', 'updating'].includes(adminClinicianListStore.loadState)}
              isChecked={!!adminClinicianDetailsStore.adminClinicianDetails?.attributes.enabled.attributes.currentValue}
              onToggle={handleEnableDisableClinician} />
          </div>
        </div>
      </div>
      <div className='clinician-details-content-panel'>
        <div className='mb-3 '>
          <FloatingTabBar
            tabNames={adminClinicianDetailsTabs(clinicianStore.clinician?.attributes.isSuperAdmin || false)}
            activeTab={activeCliniciansTab}
            onSelectTab={(tab) => setActiveCliniciansTab(tab as TAdminClinicianDetailsFilter)}
          />
        </div>
        {displayClinicianDetailsPanelContent()}
      </div>
    </div>
  )
})
