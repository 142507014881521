import { useParams } from 'react-router-dom'

import { Header } from '../Header'
import { AddCourseForm } from './components/AddCourseForm'

import './components/index.scss'

// This component is a placeholder, so that the form can load independently of the header component.
const AddCourse: React.FC = () => {
  const params = useParams()

  const patientId = params['patientId']!

  return (
    <>
      <Header patientId={patientId} isOnline={false} showEditButton />
      <AddCourseForm patientId={patientId} />
    </>
  )
}

export { AddCourse }
