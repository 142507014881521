import { useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { BackArrowButton, Button, Icons, LOADING_GREY, WHITE } from '@doseme/cohesive-ui'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { showErrorToast, showSuccessToast } from '../../../../../../shared/toast'
import {
  useAdminVendorDetailsStore,
  useAdminVendorListStore
} from '../../../../../../hooks/useStore'
import { useFormValidation } from '../../../../../../hooks/useFormValidation'
import { buildFormFields, buildTextInput } from '../../../../../../shared/buildForms'
import { handleBackButton } from '../../../../../../utils/navigation'

import './index.scss'

export const AddVendor: React.FC = observer(() => {
  const adminVendorListStore = useAdminVendorListStore()
  const adminVendorDetailsStore = useAdminVendorDetailsStore()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    adminVendorDetailsStore.fetchAdminAddVendorDetails()
  }, [])

  const formFields = useMemo(() => {
    if (
      adminVendorDetailsStore.settingsLoadStates.addVendorDetails === 'loaded' &&
      adminVendorDetailsStore.adminAddVendorDetails
    ) {
      return buildFormFields(adminVendorDetailsStore.adminAddVendorDetails)
    }

    return {}
  }, [adminVendorDetailsStore.settingsLoadStates.addVendorDetails])

  const form = useFormValidation(formFields)

  useEffect(() => {
    if (adminVendorDetailsStore.settingsLoadStates.addVendorDetails === 'loaded') {
      form.reset()
    }
  }, [adminVendorDetailsStore.settingsLoadStates.addVendorDetails, formFields])

  const formContent = (): JSX.Element => {
    if (
      adminVendorDetailsStore.adminAddVendorDetails &&
      adminVendorDetailsStore.settingsLoadStates.addVendorDetails === 'loaded'
    ) {
      return (
        <div>
          <div className='add-vendor-input'>
            {buildTextInput(
              adminVendorDetailsStore.adminAddVendorDetails.attributes.name,
              form,
              formFields['name'],
              'name'
            )}
          </div>
          <div className='add-vendor-input'>
            {buildTextInput(
              adminVendorDetailsStore.adminAddVendorDetails.attributes.key,
              form,
              formFields['key'],
              'key'
            )}
          </div>
        </div>
      )
    }

    return (
      <div>
        <Icons.ThinSpinner strokeWidth={12} r={32} stroke={LOADING_GREY} width='64px' />
      </div>
    )
  }

  const submitVendor = async () => {
    const newVendorId = await adminVendorListStore.createVendor(form.inputs['name'], form.inputs['key'])

    if (!newVendorId || adminVendorListStore.loadState === 'updateError') {
      showErrorToast(adminVendorListStore.error || 'Failed to create vendor')

      return
    }

    if (newVendorId) {
      showSuccessToast('Vendor created')
      navigate(`../${newVendorId}/sites`, { state: { from: '/admin/vendors' } })
    }
  }

  return (
    <div data-testid='add-vendor' className='co-add-vendor-page'>
      <div className='add-vendor-heading'>
        <div className='add-vendor-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() =>
              handleBackButton('../.', navigate, location)
            }
          />
        </div>
        <div className='add-vendor-title'>
          Vendors: <b>Create new vendor</b>
        </div>
      </div>
      <div className='add-vendor-content-panel'>
        <div className='add-vendor-content-title'>Vendor details</div>
        <div className='add-vendor-inputs-wrapper'>
          {formContent()}
          <Button
            disabled={
              ['loading', 'updating'].includes(adminVendorListStore.loadState) ||
              !form.inputs['name'] ||
              !form.inputs['key']
            }
            loading={
              ['loading', 'updating'].includes(adminVendorDetailsStore.settingsLoadStates.addVendorDetails) ||
              ['loading', 'updating'].includes(adminVendorListStore.loadState)
            }
            className='add-vendor-submit-btn'
            onClick={submitVendor}
            variant='primary'
          >
            Save & create vendor&nbsp;&nbsp;
            <FontAwesomeIcon color={WHITE} icon={faArrowRight} />
          </Button>
        </div>
      </div>
    </div>
  )
})
