import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, DOSEME_BLUE, InfoBubble, NAVY, VerificationCodeInputs, WHITE } from '@doseme/cohesive-ui'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TwilioAuthIcon } from './components/TwilioAuthIcon'
import { GoogleAuthIcon } from './components/GoogleAuthIcon'
import { MicrosoftAuthIcon } from './components/MicrosoftAuthIcon'
import { TTwoFASetupPages } from './types'
import { useAuthStore, useClinicianStore } from '../../../../hooks/useStore'
import { verificationCodeInitialValue } from './constants'
import { loginRedirect } from '../LoginPage/utils'

import './index.scss'

export const TwoFASetupPage: React.FC = () => {
  const [twoFASetupPage, setTwoFASetupPage] = useState<TTwoFASetupPages>('info')
  const [verificationCode, setVerificationCode] = useState<string[]>(verificationCodeInitialValue)

  const navigate = useNavigate()

  const authStore = useAuthStore()
  const clinicianStore = useClinicianStore()

  useEffect(() => {
    const element = document.getElementsByClassName('verification-code-input') as HTMLCollectionOf<HTMLInputElement>

    if (element[0]) {
      element[0].focus()
    }
  }, [twoFASetupPage])

  const sendVerificationCode = async (code: string) => {
    if (!code) return

    await authStore.authPost2FA(code)

    if (authStore.loadState === 'loaded') {
      await authStore.authSession()
      loginRedirect(authStore.auth, clinicianStore).then((redirectURL) =>
        navigate(redirectURL)
      )

      return
    }

    setVerificationCode(verificationCodeInitialValue)

    const element = document.getElementsByClassName('verification-code-input') as HTMLCollectionOf<HTMLInputElement>
    if (element[0]) {
      element[0].focus()
    }
  }

  const showSetupInfo: JSX.Element = (
    <div>
      <div className='twofa-setup-page-title'>2-factor authentication is now required</div>
      <div className='twofa-setup-page-subtitle'>
        Your site has elected to enforce two-step authentication with every login.
      </div>
      <div className='twofa-setup-page-text'>
        Each time you sign in to your DoseMeRx account, you’ll need to enter your password as well as a secondary
        verification code.
      </div>
      <div className='mt-4 mb-4'>
        <InfoBubble type='info' bubbleTitle='About 2FA:' borderRadius={8}>
          <div className='font-black mb-2'>
            Two-factor authentication (2FA) is a security system that requires two separate, distinct forms of
            verification in order to access something.
          </div>
          <div className='font-black'>
            The first factor is your password, and the second is a time-based one-time passcode (TOTP) accessed through
            an authenticator app via your smartphone.
          </div>
        </InfoBubble>
      </div>
      <div className='twofa-setup-page-subtitle'>
        If you don't have one, download an authenticator app on your smartphone now.
      </div>
      <div className='twofa-setup-page-text'>You can use any authenticator app that you trust.</div>
      <div className='twofa-setup-page-text mt-2'>These are a few TOTP authenticator apps we suggest:</div>
      <div className='totp-images-div'>
        <div className='totp-images left'>
          <TwilioAuthIcon />
          <div className='totp-images-text'>Twilio Authy</div>
        </div>
        <div className='totp-images'>
          <GoogleAuthIcon />
          <div className='totp-images-text'>Google Authenticator</div>
        </div>
        <div className='totp-images'>
          <MicrosoftAuthIcon />
          <div className='totp-images-text'>Microsoft Authenticator</div>
        </div>
      </div>
      <div className='twofa-setup-page-subtitle'>
        Once you have opened an Authenticator App, you can set up 2FA for DoseMeRx.
      </div>
      <div className='twofasetup-page-submit-btn'>
        <Button
          onClick={() => setTwoFASetupPage('qrcode')}
          variant='primary-alt'>
          Set up 2FA for DoseMeRx&nbsp;
          <FontAwesomeIcon className='ml-2' color={DOSEME_BLUE} icon={faArrowRight} />
        </Button>
      </div>
    </div>
  )

  const submitDisabled = ['loading', 'updating'].includes(authStore.loadState) || verificationCode.includes('')

  const showAuthCodes = () => {
    if (authStore.twoFASetUp) {
      return (
        <div>
          <div className='twofasetup-page-hyperlink-text' onClick={() => setTwoFASetupPage('info')}>
            <FontAwesomeIcon className='mr-2' color={NAVY} icon={faArrowLeft} />
            Go back to information
          </div>
          <div className='twofa-setup-page-title'>2-factor authentication is now required</div>
          <div className='twofa-setup-page-subtitle larger-font'>Linking your authenticator App with DoseMeRx</div>
          <div className='twofa-setup-page-text font-weight-bold'>
            in your Authenticator App tap the <a className='twofasetup-page-code-input-subtitle'>+</a> or{' '}
            <a className='twofasetup-page-code-input-subtitle'>Add Account</a> and scan this QR code:
          </div>
          <div className='totp-images-div code-input'>
            <div className='qr-code-div'>
              {twoFASetupPage === 'qrcode'
                ? <img
                  id='qrcode'
                  width='136'
                  height='136'
                  src={'data:image/png;base64,' + authStore.twoFASetUp.attributes.qrCode}
                />
                : (
                  <div className='twofasetup-page-set-up-key'>
                    {authStore.twoFASetUp.attributes.setupKey}
                  </div>
                )
              }
              <div
                className='twofasetup-page-hyperlink-text qr-code'
                onClick={() =>
                  twoFASetupPage === 'qrcode' ? setTwoFASetupPage('digitcode') : setTwoFASetupPage('qrcode')
                }
              >
                {twoFASetupPage === 'qrcode' ? 'Use setup key instead' : 'Scan QR code instead'}
              </div>
            </div>
          </div>
          <div className='twofa-setup-page-input-title'>
            <b>Enter verification code</b> <br />
            6-digit code generated in your authenticator app:
          </div>
          <div className='twofasetup-digit-input-div'>
            <VerificationCodeInputs
              code={verificationCode}
              setCode={setVerificationCode}
              onSubmit={() => sendVerificationCode(verificationCode.join(''))}
            />
          </div>
          {['loadError', 'updateError'].includes(authStore.loadState) && (
            <div className='twofasetup-error'>
              <InfoBubble type='error' bubbleTitle='Invalid verification code, please try again.' borderRadius={6} />
            </div>
          )}

          <div className='twofasetup-page-submit-btn'>
            <Button
              onClick={() => sendVerificationCode(verificationCode.join(''))}
              variant='primary-alt'
              disabled={submitDisabled}
              loading={['loading', 'updating'].includes(authStore.loadState)}
            >
              Authenticate & log in&nbsp;
              <FontAwesomeIcon className='ml-2' color={submitDisabled ? WHITE : DOSEME_BLUE} icon={faArrowRight} />
            </Button>
          </div>
        </div>
      )
    }
  }

  return (
    <div className='twofa-setup-page-panel'>
      {twoFASetupPage === 'info' && showSetupInfo}
      {(twoFASetupPage === 'qrcode' || twoFASetupPage === 'digitcode') && showAuthCodes()}
    </div>
  )
}
