import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { BackArrowButton, Button, WHITE } from '@doseme/cohesive-ui'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  useAdminVendorListStore,
  useAdminVendorSiteDetailsStore,
  useAdminVendorSiteListStore
} from '../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../shared/toast'
import { useFormValidation } from '../../../../../../../hooks/useFormValidation'
import { buildFormFields, buildInputs } from '../../../../../../../shared/buildForms'
import { handleBackButton } from '../../../../../../../utils/navigation'

import './index.scss'

export const AddVendorSite: React.FC = observer(() => {
  const [vendorName, setVendorName] = useState<string | undefined>(undefined)

  const adminVendorListStore = useAdminVendorListStore()
  const adminVendorSiteListStore = useAdminVendorSiteListStore()
  const adminVendorSiteDetailsStore = useAdminVendorSiteDetailsStore()

  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const vendorId = params['vendorId']!

  useEffect(() => {
    adminVendorSiteDetailsStore.fetchAdminAddVendorSiteDetails(vendorId)

    if (adminVendorListStore.loadState === 'initial') {
      adminVendorListStore.fetchVendors()
    }
  }, [])

  useEffect(() => {
    if (adminVendorListStore.loadState === 'loaded') {
      const vendorName = adminVendorListStore.adminVendors.get(vendorId)?.attributes.name
      setVendorName(vendorName)
    }
  }, [adminVendorListStore.loadState])

  const formFields = useMemo(() => {
    if (
      adminVendorSiteDetailsStore.settingsLoadStates.addVendorSiteDetails === 'loaded' &&
      adminVendorSiteDetailsStore.adminAddVendorSiteDetails
    ) {
      return buildFormFields(adminVendorSiteDetailsStore.adminAddVendorSiteDetails)
    }

    return {}
  }, [adminVendorSiteDetailsStore.settingsLoadStates.addVendorSiteDetails])

  const form = useFormValidation(formFields)

  useEffect(() => {
    if (adminVendorSiteDetailsStore.settingsLoadStates.addVendorSiteDetails === 'loaded') {
      form.reset()
    }
  }, [adminVendorSiteDetailsStore.settingsLoadStates.addVendorSiteDetails, formFields])

  const formContent = (): JSX.Element => {
    // FIXME - The hospital "name" requirement in the payload will be removed with IFE-918
    const displayOrder = ['hospital', 'name', 'key']
    if (
      adminVendorSiteDetailsStore.settingsLoadStates.addVendorSiteDetails === 'loaded' &&
      adminVendorSiteDetailsStore.adminAddVendorSiteDetails &&
      formFields
    ) {
      return (
        <div className='add-vendor-inputs-wrapper'>
          {buildInputs(adminVendorSiteDetailsStore.adminAddVendorSiteDetails, form, displayOrder, formFields)}
        </div>
      )
    }

    return <></>
  }

  const submitVendorSite = async () => {
    const vendorSiteId = await adminVendorSiteListStore.createVendorSite(
      vendorId,
      form.inputs['key'],
      form.inputs['name'],
      form.inputs['hospital']
    )

    if (!vendorSiteId || adminVendorSiteListStore.loadState === 'updateError') {
      showErrorToast(adminVendorSiteListStore.error || 'Failed to create vendor site')

      return
    }

    if (vendorSiteId) {
      showSuccessToast('Vendor Site created')
      navigate(`../${vendorSiteId}`, { state: { from: `/admin/vendors/${vendorId}/sites` } })
    }
  }

  return (
    <div data-testid='add-vendor' className='co-add-vendor-site-page'>
      <div className='add-vendor-site-heading'>
        <div className='add-vendor-site-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() =>
              handleBackButton('../.', navigate, location)
            }
          />
        </div>
        <div className='add-vendor-site-title'>
          {vendorName}: <b>Add vendor site</b>
        </div>
      </div>
      <div className='add-vendor-site-content-panel'>
        <div className='add-vendor-site-content-title'>Site details</div>
        <div className='add-vendor-site-inputs-wrapper'>
          {formContent()}
          <Button
            disabled={
              ['loading', 'updating'].includes(adminVendorSiteListStore.loadState) ||
              !form.inputs['hospital'] ||
              !form.inputs['name'] ||
              !form.inputs['key']
            }
            loading={
              ['loading', 'updating'].includes(adminVendorSiteDetailsStore.settingsLoadStates.addVendorSiteDetails) ||
              ['loading', 'updating'].includes(adminVendorSiteListStore.loadState)
            }
            className='add-vendor-site-submit-btn'
            onClick={submitVendorSite}
            variant='primary'
          >
            Save & create site&nbsp;&nbsp;
            <FontAwesomeIcon color={WHITE} icon={faArrowRight} />
          </Button>
        </div>
      </div>
    </div>
  )
})
