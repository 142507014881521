import React, { useEffect } from 'react'
import { decode } from 'he'
import { Icons } from '@doseme/cohesive-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import { IChangeLogItem, IChangeLogValue } from '../../../../../store/Admin/AdminChangeLog/types'
import { useAuthStore, useHospitalStore } from '../../../../../hooks/useStore'
import { formatToDisplayDate } from '../../../../../utils/dates'

import './index.scss'

interface IProps {
  changeLog: IChangeLogItem
}

const ChangeLogEntry: React.FC<IProps> = ((props) => {
  const hospitalStore = useHospitalStore()
  const authStore = useAuthStore()

  useEffect(() => {
    if (hospitalStore.loadState === 'initial' && authStore.auth && authStore.isAuthenticated()) {
      hospitalStore.fetchHospital(authStore.auth.attributes.hospitalId)
    }
  }, [])

  const getValueString = (value?: string, valueUnits?: string) => {
    // FIXME - IFE-1082 - Gets the value from an object if value is somehow passed as an object
    // Added to correct a bug fixed in WEB-5524 - Ideally this should be removed and the DB records fixed
    if (value && typeof value === 'object') {
      //@ts-ignore
      value = value.value
    }

    // 0 is still a valid value and not equivalent to none
    if (value === undefined || value === null) {
      return 'None'
    }

    return valueUnits
      ? `${decode(value.toString())} ${valueUnits}`
      : decode(value.toString())
  }

  const renderEditValues = (): JSX.Element[] => {
    return props.changeLog.attributes.values.map((changeLogValue: IChangeLogValue) => {
      return (
        <div key={`${decode(changeLogValue.label || '')} ${decode(changeLogValue.subLabel || '')}`} className='change-log-entry-value'>
          <FontAwesomeIcon className='change-log-entry-bullet-point' icon={faCircle} />
          <p className='change-log-entry-value-text'>
            <b className='change-log-entry-value-text-edit-label'>{decode(changeLogValue.label || '')}:&nbsp;</b>
            {changeLogValue.subLabel
              ? `${decode(changeLogValue.subLabel || '')} `
              : ''
            }
            <span className='change-log-entry-value-text-edit-from'>
              { getValueString(changeLogValue.from, changeLogValue.fromUnits) }
            </span>
            &nbsp;→&nbsp;
            { getValueString(changeLogValue.to, changeLogValue.toUnits) }
          </p>
        </div>
      )
    })
  }

  const renderValue = () => {
    return (
      <div className='change-log-entry-value'>
        <FontAwesomeIcon className='change-log-entry-bullet-point' icon={faCircle} />
        <p className='change-log-entry-value-text'>
          {props.changeLog.attributes.change}
          <b>{decode(props.changeLog.attributes.values[0].label || '')}</b>
          {props.changeLog.attributes.join
            ? props.changeLog.attributes.join
            : ''}
          <b>
            {props.changeLog.attributes.join
              ? decode(props.changeLog.attributes.values[1].label || '')
              : ''}
          </b>
        </p>
      </div>
    )
  }

  return (
    <div key={props.changeLog.id} className='change-log-entry-panel'>
      <div className='change-log-entry-date-and-icon'>
        <Icons.ActionIcon height={24} width={24} />
        {hospitalStore.hospital?.attributes.timezone
          ? formatToDisplayDate(props.changeLog.attributes.created, hospitalStore.hospital.attributes.timezone)
          : ''}
      </div>
      <div className='change-log-entry-details' >
        <div className='change-log-entry-clinician'>
          <span className='change-log-entry-bold'>{decode(props.changeLog.attributes.clinician.name || '')}</span>
          &nbsp;made the following updates:
        </div>
        <div className='change-log-entry-target'>
          <span className='change-log-entry-bold'>{decode(props.changeLog.attributes.target.label || '')}:</span>
          {` ${decode(props.changeLog.attributes.target.subLabel || '')}`}
        </div>
        <div className='change-log-entry-values-container'>
          {props.changeLog.attributes.change === 'Edit'
            ? renderEditValues()
            : renderValue()
          }
        </div>
      </div>
    </div>
  )
})

export { ChangeLogEntry }
