import { useContext } from 'react'

import {
  RootStoreContext,
  ErrorsStoreContext,
  AuthStoreContext,
  PasswordRulesStoreContext,
  HospitalStoreContext,
  PatientStoreContext,
  PatientExternalDataStoreContext,
  PatientListStoreContext,
  CourseFeaturesStoreContext,
  CourseStoreContext,
  PatientCoursesStoreContext,
  AdministrationsStoreContext,
  ObservationsStoreContext,
  DrugModelsStoreContext,
  HistoricalSimulationStoreContext,
  DosingRecommendationStoreContext,
  ActivityLogStoreContext,
  GenerateReportStoreContext,
  DrugModelsInformationStoreContext,
  DosingMatrixStoreContext,
  VersionsStoreContext,
  AdminHospitalListStoreContext,
  AdminVendorListStoreContext,
  AdminVendorDetailStoreContext,
  AdminVendorSiteDetailStoreContext,
  AdminVendorSiteListStoreContext,
  AdminHospitalDrugListStoreContext,
  AdminHospitalDetailsStoreContext,
  ConstantsTimezoneStoreContext,
  ApplicableDrugModelsStoreContext,
  ClinicianStoreContext,
  AdminClinicianListStoreContext,
  AdminClinicianDetailsStoreContext,
  AdminClinicianSecurityStoreContext,
  AdminHospitalDrugDetailsStoreContext,
  AnalyticsStoreContext,
  AdminChangeLogListStoreContext
} from '../store/StoreProvider'

export const useStore = () => useContext(RootStoreContext)
export const useErrorsStore = () => useContext(ErrorsStoreContext)
export const useAuthStore = () => useContext(AuthStoreContext)
export const usePasswordRulesStore = () => useContext(PasswordRulesStoreContext)
export const useHospitalStore = () => useContext(HospitalStoreContext)
export const usePatientStore = () => useContext(PatientStoreContext)
export const usePatientExternalDataStore = () => useContext(PatientExternalDataStoreContext)
export const usePatientListStore = () => useContext(PatientListStoreContext)
export const useCourseFeaturesStore = () => useContext(CourseFeaturesStoreContext)
export const useCourseStore = () => useContext(CourseStoreContext)
export const usePatientCoursesStore = () => useContext(PatientCoursesStoreContext)
export const useAdministrationsStore = () => useContext(AdministrationsStoreContext)
export const useObservationsStore = () => useContext(ObservationsStoreContext)
export const useDrugModelsStore = () => useContext(DrugModelsStoreContext)
export const useHistoricalSimulationStore = () => useContext(HistoricalSimulationStoreContext)
export const useDosingRecommendationStore = () => useContext(DosingRecommendationStoreContext)
export const useActivityLogStore = () => useContext(ActivityLogStoreContext)
export const useGenerateReportStore = () => useContext(GenerateReportStoreContext)
export const useDrugModelsInformationStore = () => useContext(DrugModelsInformationStoreContext)
export const useDosingMatrixStore = () => useContext(DosingMatrixStoreContext)
export const useVersionsStore = () => useContext(VersionsStoreContext)
export const useAdminHospitalListStore = () => useContext(AdminHospitalListStoreContext)
export const useAdminVendorListStore = () => useContext(AdminVendorListStoreContext)
export const useAdminVendorDetailsStore = () => useContext(AdminVendorDetailStoreContext)
export const useAdminVendorSiteDetailsStore = () => useContext(AdminVendorSiteDetailStoreContext)
export const useAdminVendorSiteListStore = () => useContext(AdminVendorSiteListStoreContext)
export const useAdminHospitalDrugListStore = () => useContext(AdminHospitalDrugListStoreContext)
export const useConstantsTimezoneStore = () => useContext(ConstantsTimezoneStoreContext)
export const useApplicableDrugModelsStore = () => useContext(ApplicableDrugModelsStoreContext)
export const useAdminHospitalDetailsStore = () => useContext(AdminHospitalDetailsStoreContext)
export const useClinicianStore = () => useContext(ClinicianStoreContext)
export const useAdminClinicianListStore = () => useContext(AdminClinicianListStoreContext)
export const useAdminClinicianDetailsStore = () => useContext(AdminClinicianDetailsStoreContext)
export const useAdminChangeLogListStore = () => useContext(AdminChangeLogListStoreContext)
export const useAdminClinicianSecurityStore = () => useContext(AdminClinicianSecurityStoreContext)
export const useAdminHospitalDrugDetailsStore = () => useContext(AdminHospitalDrugDetailsStoreContext)
export const useAnalyticsStore = () => useContext(AnalyticsStoreContext)
